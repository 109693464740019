import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShareToSocialModalViewModel} from './share-to-social-modal-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {KrugoMapMarker} from '../map/interfaces/krugo-map-marker';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScreenService} from '../../../../../services/screen.service';
import {TypeUtils} from '../../../utils/type-utils';
import * as FileSaver from 'file-saver';
import * as htmlToImage from 'html-to-image';
import {BlobUtils} from '../../../../../utils/blob-utils';
import {delay, filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-share-to-social-modal.component',
  templateUrl: './share-to-social-modal.component.html',
  styleUrls: ['./share-to-social-modal.component.scss'],
  providers: [ShareToSocialModalViewModel],
})
export class ShareToSocialModalComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('socialPost') socialPostElement: ElementRef;
  @Input() item: KrugoMapMarker;
  types = TypeUtils;

  constructor(
    public vm: ShareToSocialModalViewModel,
    private activeModal: NgbActiveModal,
    private screenService: ScreenService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.screenService.publishScrollPosition.next(this.el.nativeElement?.getBoundingClientRect()?.top ?? 0);
    }, 100);
    this.setupBindings();
  }

  setupBindings() {
    const s = this.vm.closeModal.subscribe(close => {
      if (close) {
        this.activeModal.close();
      }
    });
    this.pushSub(s);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  close() {
    this.activeModal.close(null);
  }

  generatePost() {
    this.vm.loadingOpts.isLoading = true;
    this.vm.contentLoadedSubject$.pipe(filter(loaded => !!loaded), take(1))
      .subscribe(() => {
          this.generateImageFromHtml();
      });
  }

  generateImageFromHtml(generateCount: number = 5) {
    const selectedOption = this.vm.selectedOption;
    const filename = `${new Date().getTime()}-${selectedOption}`;
    htmlToImage.toJpeg(this.socialPostElement.nativeElement,
      {
        pixelRatio: 1,
        canvasHeight: this.vm.getHeightForExportType(selectedOption),
        canvasWidth: this.vm.getWidthForExportType(selectedOption),
        height: this.vm.getHeightForExportType(selectedOption),
        width: this.vm.getWidthForExportType(selectedOption),
      },
    ).then(image => {
      // This is very hacky, but it was the only way I could get it to generate with the images reliably on ios safari
      if (generateCount > 0) {
        this.generateImageFromHtml(generateCount - 1);
        return;
      }
      const blob = BlobUtils.b64toBlob(image, 'image/jpeg');
      if (window.saveAs) {
        window.saveAs(blob, filename);
      } else {
        FileSaver.saveAs(blob, filename);
      }
      this.vm.loadingOpts.isLoading = false;
      this.close();
    });
  }
}
