import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() containerClass: string;
  @Input() backgroundColor: string;
  @Input() textColor: string;
  @Input() minHeight: string;
  @Input() clickable: boolean = true;
  @Input() startAndEndPadding: string = '1rem';
  public dismissed: boolean = false;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  toggle() {
    this.dismissed = !this.dismissed;
  }

  ngOnDestroy(): void {
  }

}
