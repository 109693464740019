import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {FontHierarchy} from '../enum/font-hierarchy.enum';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {FontType} from '../enum/font-type.enum';
import {FontUtils} from '../utils/font-utils';
import {GuidesDomainModel} from '../domain/guides-domain-model';
import {InsiderDomainModel} from '../domain/insider-domain-model';

@Injectable()
export class FontViewModel extends BaseViewModel {

  private fontHierarchy = new BehaviorSubject<FontHierarchy>(FontHierarchy.Primary);
  private fontTypeSubject = new BehaviorSubject<FontType>(FontType.Regular);
  public fontClass = combineLatest([
    this.fontHierarchy.pipe(distinctUntilChanged()),
    this.fontTypeSubject.pipe(distinctUntilChanged()),
    this.insiderDomainModel.primaryFont$.pipe(distinctUntilChanged()),
    this.insiderDomainModel.secondaryFont$.pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(1),
    map(([hierarchy, type, primary, secondary]) => {
      switch (hierarchy) {
        case FontHierarchy.Primary:   return FontUtils.getFontClass(type, primary);
        case FontHierarchy.Secondary: return FontUtils.getFontClass(type, secondary);
      }
    }),
    distinctUntilChanged()
  );

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setType(t: FontType) {
    this.fontTypeSubject.next(t);
  }

  setHierarchy(h: FontHierarchy) {
    this.fontHierarchy.next(h);
  }

}
