<button appFont
        [hierarchy]="types.FontHierarchy.Primary"
        [type]="types.FontType.Regular"
        class="custom-pill"
        [ngClass]="{ 'active' : selected, 'hoverable' : clickable && !disabled }"
        [disabled]="disabled"
        (click)="clicked()">
    <ng-content *ngIf="!text"></ng-content>
    <ng-container *ngIf="text">{{text}}</ng-container>
</button>
