import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {InsiderDomainModel} from '../../../domain/insider-domain-model';
import {ScreenService} from '../../../../../services/screen.service';

@Injectable()
export class HomeViewModel extends BaseViewModel {

  public selectedGuide$ = this.guidesDomainModel.selectedGuide$;
  public primaryColor$ = this.insiderDomainModel.primaryColor$;
  public isMobile$ = this.screenService.connectToIsMobile();
  public insider$ = this.insiderDomainModel.insider$;

  // Listeners
  private listenToActivatedRoute = this.activatedRoute.params
    .pipe(takeUntil(this.onDestroy))
    .subscribe(p => this.initFromUrl(p));

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private screenService: ScreenService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  private initFromUrl(p: Params) {
    const insiderId = p.insiderId;
    if (insiderId) {
      this.guidesDomainModel.setInsiderId(insiderId);
      this.insiderDomainModel.setInsiderId(insiderId);
    }
  }

  destroy() {
    super.destroy();
    this.guidesDomainModel.destroy();
  }

}
