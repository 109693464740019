import {BaseViewModel} from '../../../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../../domain/guides-domain-model';
import {Guide} from '../../../../../../../models/shared/guide';
import {KrugoMapMarker} from '../../../map/interfaces/krugo-map-marker';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, takeUntil} from 'rxjs/operators';
import {DistinctUtils} from '../../../../../../../utils/distinct.utils';
import {ElementRef, Injectable} from '@angular/core';
import {ScreenService} from '../../../../../../../services/screen.service';
import {MapService} from '../../../map/services/map.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MapItemDetailsComponent} from '../../../map/components/map-item-details/map-item-details.component';
import {ModalUtils} from '../../../../../../../utils/modal-utils';
import {InsiderDomainModel} from '../../../../../domain/insider-domain-model';
import {ScrollUtils} from '../../../../../utils/scroll-utils';
import {Insider} from '../../../../../../../models/shared/insider';
import {GuideClickType} from '../../../../../../../models/guides/add-guide-view-req';

@Injectable()
export class GuideSectionItemViewModel extends BaseViewModel {

  public primaryColor$ = this.insiderDomainModel.primaryColor$;
  private guide = new BehaviorSubject<Guide>(null);
  private itemId = new BehaviorSubject<string>(null);
  private selectedMapMarker = new BehaviorSubject<KrugoMapMarker>(null);

  // Find Item from Id
  public item$ = combineLatest([
    this.guide.pipe(distinctUntilChanged(DistinctUtils.distinctGuide)),
    this.itemId.pipe(distinctUntilChanged())
  ]).pipe(
    map(([guide, id]) => guide?.findPlanItem(id)),
    distinctUntilChanged(DistinctUtils.distinctKrugoMapMarker),
    shareReplay(1)
  );

  // Item index
  public itemIndex$ = combineLatest([
    this.guide.pipe(distinctUntilChanged(DistinctUtils.distinctGuide)),
    this.itemId.pipe(distinctUntilChanged())
  ]).pipe(
    map(([guide, id]) => guide?.findPlanItemIndex(id) + 1),
    distinctUntilChanged(),
    shareReplay(1)
  );

  // Is the current item selected?
  public isItemSelected$ = combineLatest([
    this.selectedMapMarker,
    this.itemId,
    this.screenService.connectToIsMobile(),
  ]).pipe(
    map(([selected, id, isMobile]) => {
      if (!isMobile) {
        return selected?.getUniqueId() === id;
      }
      return false;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  // Scroll to selected item
  public scrollToSelected = combineLatest([
    this.isItemSelected$,
    this.screenService.connectToIsMobile()
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([selected, isMobile]) => {
      if (selected && !isMobile) {
        ScrollUtils.verticalScrollIntoView(this.el.nativeElement.parentElement.parentElement.parentElement, this.el.nativeElement);
      }
    });

  // Guide item hovered
  private itemHover = new Subject<boolean>();
  private selectItemOnHover = combineLatest([
    this.itemHover.pipe(debounceTime(100)),
    this.screenService.connectToIsMobile(),
    this.item$,
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([toggle, isMobile, item]) => {
      if (toggle && !isMobile && item) {
        this.mapService.changeSelectedMapMarker(item);
      }
    });

  // Guide item selected
  private itemClicked = new Subject<boolean>();
  private openModal = combineLatest([
    this.itemClicked,
    this.item$
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([_, item]) => {
      if (item) {
        this.openGuideDetails(item);
      }
    });

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private screenService: ScreenService,
    private mapService: MapService,
    private modalService: NgbModal,
    private el: ElementRef,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setGuide(g: Guide) {
    this.guide.next(g);
  }

  setItemId(id: string) {
    this.itemId.next(id);
  }

  setSelectedMapMarker(s: KrugoMapMarker) {
    this.selectedMapMarker.next(s);
  }

  hoverToggled() {
    this.itemHover.next(true);
  }

  clickToggled() {
    this.itemClicked.next(true);
  }

  private openGuideDetails(item: KrugoMapMarker): void {
    const modalRef = this.modalService.open(
      MapItemDetailsComponent,
      ModalUtils.guideItemDetailsModalOptions()
    );
    (modalRef.componentInstance as MapItemDetailsComponent).item = item;

  }

  addView(type: GuideClickType) {
    const guideId = this.guidesDomainModel.guideId.getValue();
    this.insiderDomainModel.addSocialView(guideId, type);
  }

}
