<div class="asset-container">
  <img *ngIf="(!(loading$|async) && (asset?.isImage() || !asset))"
       #image
       class="asset"
       (load)="ratio.emit(image.naturalWidth/image.naturalHeight);"
       [style.object-fit]="scaleFit ? 'contain' : 'cover'"
       [style.border-radius]="borderRadius"
       [src]="distinctAsset$|async"
       alt="">
  <video *ngIf="!(loading$|async) && asset?.isVideo()"
         #video
         playsinline
         class="asset"
         [controls]="showControls"
         [src]="(distinctAsset$|async)"
         [autoplay]="autoplay"
         [loop]="loopVideo"
         [style.object-fit]="scaleFit ? 'contain' : 'cover'"
         [style.border-radius]="borderRadius"
         (loadedmetadata)="duration.emit(video.duration); ratio.emit((video.videoWidth/video.videoHeight) || 1);"
         (ended)="this.videoEnded.emit($event.returnValue)"
         [muted]="true">
  </video>
  <div *ngIf="loading$|async"
       #loading
       class="asset">
    <ngx-shimmer-loading [width]="'100%'"
                         [height]="'100%'"
                         [shape]="'rect'"
                         [borderRadius]="borderRadius">
    </ngx-shimmer-loading>
  </div>
</div>
