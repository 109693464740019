export class UrlUtils {
  static formatUrl(url: string) {
    if (!!url) {
      if (url.includes('http://') || url.includes('https://')) {
        return url;
      } else {
        return `https://${url}`;
      }
    } else {
      return null;
    }
  }
}
