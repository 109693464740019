import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SessionContainer} from '../models/shared/session-container';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {CodeDeliveryDetails} from '../models/account/dto/code-delivery-details';
import {SignOutRequest} from '../models/account/requests/sign-out-request';
import {CacheService} from './cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {DateUtils} from '../utils/date-utils';
import '../utils/observable.extensions';

@Injectable({
  providedIn: 'root'
})

export class SessionService {

  // Behaviour Subjects
  public refreshingSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sessionContainer: BehaviorSubject<SessionContainer> = new BehaviorSubject<SessionContainer>(null);
  public destroySession: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deadSession: boolean = true;

  constructor(
    private cacheService: CacheService,
  ) {
    this.setupBindings();

  }

  public setupBindings() {
    this.sessionContainer.notNull().subscribe((sess) => {
      if (sess && this.sessionContainer.getValue().rememberSession) {
        // save session to persistent cache
        this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess, true);
      }
      // save session to session cache
      this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess);
    });

    this.destroySession.notNull().subscribe((shouldDestroy) => {
      if (shouldDestroy) {
        this.deadSession = true;
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this.sessionContainer.next(null);
      }
    });
  }

  public getCachedSession(): SessionContainer {
    // Get user session from cache, checking session cache first
    let sess: SessionContainer;
    sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer);
    if (!sess) {
      // Check the persistent cache for a session
      sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer, true);
    }
    this.deadSession = false;
    this.sessionContainer.next(sess);
    return sess;
  }

  // Getters

  public liveSession(): boolean {
    return !this.deadSession;
  }

  public getUser(): HydratedUser {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user;
    }
    return null;
  }

  public getUserId(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.userId;
    }
    return '';
  }

  public getUserEmail(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.email;
    }
    return '';
  }

  public getAuthToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.accessToken;
    }
    return '';
  }

  public getRefreshToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.refreshToken;
    }
    return '';
  }

  public getSessionChallengeToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.challenge.authSession;
    }
    return '';
  }

  public getRefreshSessionReq(sess?: SessionContainer): RefreshSessionRequest {
    if (sess) {
      const userId = sess.user?.userId;
      const refreshToken = sess.user?.session?.refreshToken;
      return new RefreshSessionRequest(userId, refreshToken);
    } else if (this.sessionContainer.getValue()) {
      const userId = this.getUserId();
      const refreshToken = this.getRefreshToken();
      return new RefreshSessionRequest(userId, refreshToken);
    } else {
      return null;
    }
  }

  public getSignOutReq(): SignOutRequest {
    if (this.sessionContainer.getValue()) {
      const userId = this.getUserId();
      const accessToken = this.getAuthToken();
      return new SignOutRequest(userId, accessToken);
    } else {
      return null;
    }
  }

  // Setters

  public setUser(u: HydratedUser, newSession: boolean, rememberSession: boolean = false) {
    const sessCopy = Object.assign(new SessionContainer(), this.sessionContainer.getValue());
    sessCopy.user = u;
    sessCopy.rememberSession = rememberSession;
    if (newSession) {
      sessCopy.sessionStartTime = DateUtils.currentTimestamp();
    }
    this.deadSession = false;
    this.sessionContainer.next(sessCopy);
  }

  public setDeliveryDetails(d: CodeDeliveryDetails) {
    const sessCopy = Object.assign(new SessionContainer(), this.sessionContainer.getValue());
    sessCopy.codeDeliveryDetails = d;
    this.sessionContainer.next(sessCopy);
  }
}
