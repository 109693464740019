import {Injectable} from '@angular/core';
import {BaseDomainModel} from '../../../models/base/base-domain-model';
import {BehaviorSubject} from 'rxjs';
import {Font} from '../enum/font.enum';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {GuideApi} from '../../../api/guide-api';
import {CustomError} from '../../../models/shared/custom-error';
import {ToastService} from '../../../services/toast-service';
import {Insider} from '../../../models/shared/insider';
import {CacheService} from '../../../services/cache-service';
import {Guide} from '../../../models/shared/guide';
import {GuideClickType} from '../../../models/guides/add-guide-view-req';

@Injectable({providedIn: 'root'})
export class InsiderDomainModel extends BaseDomainModel {

  // Query Data
  private insiderId = new BehaviorSubject<string>(null);

  // Insider
  private insider = new BehaviorSubject<Insider>(null);
  public insider$ = this.insider.asObservable();
  private listenToInsider = this.insider
    .pipe(takeUntil(this.onDestroy))
    .subscribe(insider => {
      if (insider) {
        if (insider?.options?.primaryFont) {
          this.primaryFont.next(insider?.options?.primaryFont);
        }
        if (insider?.options?.secondaryFont) {
          this.secondaryFont.next(insider?.options?.secondaryFont);
        }
        if (insider?.options?.primaryColor) {
          this.primaryColor.next(insider?.options?.primaryColor);
        }
        if (insider?.options?.secondaryColor) {
          this.secondaryColor.next(insider?.options?.secondaryColor);
        }
      }
    });

  // Fetch Insider
  private fetchInsider = this.insiderId
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged())
    .subscribe(insiderId => {
      if (insiderId) {
        this.guideApi.GetInsider(insiderId).subscribe(insider => {
          if (insider) {
            this.cache.cacheObject<Insider>(insider.cacheKey(), insider);
            this.insider.next(insider);
          }
        }, (error: CustomError) => { this.toastService.publishError(error); });
      }
    });

  // Fonts
  private primaryFont = new BehaviorSubject<Font>(Font.WorkSans);
  private secondaryFont = new BehaviorSubject<Font>(Font.WorkSans);

  // Colors
  private primaryColor = new BehaviorSubject<string>('#222222');
  private secondaryColor = new BehaviorSubject<string>('#222222');

  // Observable
  public primaryFont$ = this.primaryFont.pipe(distinctUntilChanged());
  public secondaryFont$ = this.secondaryFont.pipe(distinctUntilChanged());
  public primaryColor$ = this.primaryColor.pipe(distinctUntilChanged());
  public secondaryColor$ = this.secondaryColor.pipe(distinctUntilChanged());

  constructor(
    private guideApi: GuideApi,
    private toastService: ToastService,
    private cache: CacheService,
  ) {
    super();
  }

  setInsiderId(id: string) {
    this.insiderId.next(id);
  }

  addSocialView(guideId: string, type: GuideClickType) {
    this.guideApi.AddGuideView(guideId, type)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => {
      }, (error: CustomError) => {
        this.toastService.publishError(error);
      });
  }

}
