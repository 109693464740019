import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Asset} from '../../../../../models/image/dto/asset';
import {AssetSize} from '../../../../../models/enum/dto/asset-size.enum';
import {SwiperOptions} from 'swiper';
import {PaginationOptions} from 'swiper/types';
import {BaseComponent} from '../../../../../models/base/base-component';
import {OverlayOptions} from '../../../../../models/shared/overlay-options';
import ResizeObserver from 'resize-observer-polyfill';
import {BehaviorSubject} from 'rxjs';
import {SwiperComponent} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-swiper-carousel',
  templateUrl: './swiper-carousel.component.html',
  styleUrls: ['./swiper-carousel.component.scss']
})
export class SwiperCarouselComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('container')
  private container: ElementRef;
  @ViewChild('swiper')
  public swiper: SwiperComponent;
  private observeContainer: ResizeObserver;
  public swiperHeight = new BehaviorSubject<string>('');
  public AssetSize: typeof AssetSize = AssetSize;
  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
  };
  private defaultConfig: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 8,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    updateOnWindowResize: true,
  };

  @Input() assets: Asset[];
  @Input() config: SwiperOptions = this.defaultConfig;
  @Input() scaleFit: boolean = false;
  @Input() overlay: OverlayOptions = OverlayOptions.default();
  @Output() index = new EventEmitter<number>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setPagination();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.observeSwiperContainer();
  }

  public onIndexChange(index: number): void {
    this.index.emit(index);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assets) {
      this.setPagination();
    }
  }

  observeSwiperContainer() {
    this.observeContainer = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        this.swiperHeight.next(`${cr.height}px`);
        window.dispatchEvent(new Event('resize'));
      }
    });
    this.observeContainer.observe(this.container.nativeElement);
  }

  setPagination() {
    this.config.navigation = ((this.assets?.length ?? 0) > 1);
    this.config.pagination = (this.assets?.length > 1) ? this.pagination : false;
  }

  ngOnDestroy(): void {
    this.destroy();
    this.observeContainer.disconnect();
  }

}
