<app-loading
  [hidden]="!vm.loadingOpts?.isLoading"
  [options]="vm.loadingOpts">
</app-loading>
<div class="modal-header">
  <div appFont
       class="modal-title"
       [type]="types.FontType.Bold"
       [hierarchy]="types.FontHierarchy.Primary">
    Share Options
  </div>
</div>
<div class="modal-body">
  <div *ngFor="let exportType of vm.exportOptions">
    <div class="export-option-row" (click)="vm.selectedOption = exportType">
      <div class="radio-option">
        <div class="radio-option-selected" [class.d-none]="vm.selectedOption !== exportType"
             [style.background-color]="vm.primaryColor$|async"></div>
      </div>
      <div>
        <div class="option-title"
             appFont
             [type]="types.FontType.Bold"
             [hierarchy]="types.FontHierarchy.Primary">
          {{vm.getTitleForExportType(exportType)}}</div>
        <div class="option-subtitle"
             appFont
             [type]="types.FontType.Bold"
             [hierarchy]="types.FontHierarchy.Primary">
          {{vm.getSizeForExportType(exportType)}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer footer-container">
  <button class="custom-button"
          appFont
          [type]="types.FontType.Bold"
          [hierarchy]="types.FontHierarchy.Primary"
          (click)="close()">
    Cancel
  </button>
  <button class="custom-button export-button"
          (click)="generatePost()"
          appFont
          [type]="types.FontType.Bold"
          [hierarchy]="types.FontHierarchy.Primary"
          [style.background-color]="vm.primaryColor$|async">
    Export Post
  </button>
</div>
<div #socialPost class="social-post-container" id="social-post">
  <div class="social-post social-post-{{vm.selectedOption}}">
    <div class="social-main-content-container"
         [style.background-image]="'url(' + (vm.postImage$|async) + ')'">
      <div class="social-main-content">
        <div class="insider-container">
          <img class="insider-image" [src]="(vm.insiderImage$|async)" alt="">
          <div class="insider-name"
               appFont
               [type]="types.FontType.Bold">
            {{vm.postInsiderName$|async}}
          </div>
        </div>
        <div class="insider-collaboration"
             appFont
             [type]="types.FontType.SemiBold"
             *ngIf="vm.postInsiderCollaborationText$|async">
          {{vm.postInsiderCollaborationText$|async}}
        </div>
        <div class="post-title"
             appFont
             [type]="types.FontType.Bold">
          {{vm.postTitle$|async}}
        </div>
        <div class="post-description"
             appFont
             [type]="types.FontType.SemiBold">
          {{vm.postDescription$|async}}
        </div>
        <div class="post-main-content-footer">
          <div class="post-spot-count"
               appFont
               [type]="types.FontType.SemiBold">
            {{vm.postSpots$|async}}
          </div>
          <div class="post-website"
               appFont
               [type]="types.FontType.SemiBold">
            View the entire guide at&nbsp; <span class="post-website-bubble">{{vm.postWebsite$|async}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="social-additional-content-container">
      <div *ngFor="let planItem of (vm.postPlaces$|async)" class="plan-item-row">
        <app-asset class="plan-item-asset"
                   [size]="types.AssetSize.Medium"
                   [createVideoPoster]="true"
                   [asset]="vm.getPlanItemImageAsset(planItem)">
        </app-asset>
        <div class="plan-item-text-content">
          <div class="plan-item-title"
               appFont
               [type]="types.FontType.SemiBold">
            {{planItem.getTitle()}}
          </div>
          <div class="plan-item-description"
               appFont
               [type]="types.FontType.Regular">
            {{planItem.getDesc()}}
          </div>
        </div>
      </div>
      <div *ngIf="(vm.postMorePlaces$|async)"
           class="more-places"
           appFont
           [type]="types.FontType.SemiBold">
        {{vm.postMorePlaces$|async}}
      </div>
    </div>
  </div>
</div>
