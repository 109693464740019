import {Injectable} from '@angular/core';
import {BaseModalViewModel} from '../../../../../models/base/base-modal-view-model';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InsiderDomainModel} from '../../../domain/insider-domain-model';
import {GuideClickType} from '../../../../../models/guides/add-guide-view-req';
import {Guide} from '../../../../../models/shared/guide';

@Injectable()
export class InsiderModalViewModel extends BaseModalViewModel {

  constructor(
    public router: Router,
    public modalService: NgbModal,
    private insiderDomainModel: InsiderDomainModel,
  ) {
    super(router, modalService);
    this.init();
  }

  init() {
    super.init();
  }

  addView(guide: Guide, type: GuideClickType) {
    this.insiderDomainModel.addSocialView(guide.id, type);
  }

}
