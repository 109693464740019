<div class="slide-container"
     matRipple
     [matRippleDisabled]="!clickable"
     [style.cursor]="clickable ? 'pointer' : 'default'"
     [ngClass]="{
       'slide-in': !dismissed,
       'slide-out': dismissed
     }">
  <div fxLayout="row"
       fxLayoutAlign="end center"
       [style.padding]="!!startAndEndPadding ? '0 '+startAndEndPadding : ''"
       [style.minHeight]="minHeight"
       [style.backgroundColor]="backgroundColor"
       [style.color]="textColor"
       [ngClass]="containerClass">
    <div fxFlex><ng-content></ng-content></div>
    <mat-icon class="close-icon"
              fxFlex="none"
              [style.color]="'white'"
              (click)="toggle(); $event.stopPropagation();">close</mat-icon>
  </div>
</div>
