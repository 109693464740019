import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Guide} from '../../../../../../models/shared/guide';
import {TypeUtils} from '../../../../utils/type-utils';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {GuideHeaderViewModel} from './guide-header-view-model';
import {ToastService} from '../../../../../../services/toast-service';
import {ScreenService} from '../../../../../../services/screen.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from '../../../../../../utils/modal-utils';
import {ShareToSocialModalComponent} from '../../share-to-social-modal/share-to-social-modal.component';

@Component({
  selector: 'app-guide-header',
  templateUrl: './guide-header.component.html',
  styleUrls: ['./guide-header.component.scss'],
  providers: [GuideHeaderViewModel],
})
export class GuideHeaderComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: Guide;
  public bannerOverlay = this.vm.bannerOverlay.asObservable();
  types = TypeUtils;

  constructor(
    public vm: GuideHeaderViewModel,
    private toastService: ToastService,
    private screenService: ScreenService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
  }

  copyLink() {
    if (navigator.share && this.screenService.isMobile.getValue()) {
      navigator.share({
        url: this.guide.getLink(),
      }).then(() => {
        this.toastService.publishSuccessMessage('', 'Link Copied!');
      });
    } else {
      navigator.clipboard.writeText(this.guide.getLink()).then((_) => {
        this.toastService.publishSuccessMessage('', 'Link Copied!');
      });
    }
  }

  shareToSocial() {
    this.modalService.open(
      ShareToSocialModalComponent,
      ModalUtils.shareToSocialModalOptions()
    );
  }
}
