// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accountServiceUrl: 'https://95ldqtgvv1.execute-api.us-west-2.amazonaws.com/staging',
  guideServiceUrl: 'https://m2qllet4al.execute-api.us-west-2.amazonaws.com/staging',
  partnerGuideServiceUrl: 'https://b8g45md8ea.execute-api.us-west-2.amazonaws.com/staging',
  cognitoClientSecret: '1fbd5p1pj0f172snm4hjgpj5vf0bsrftrio8e7vpp87ejvd9tvt',
  googleMapsApiKey: 'AIzaSyCaRAtODL-AFMKEY4y2AAWmDobWKuBTy24',
  firebaseConfig: {
    apiKey: 'AIzaSyCUySceErJGjdPpnGddApqXbNs2vbVHgSM',
    authDomain: 'krugo-partners-staging.firebaseapp.com',
    projectId: 'krugo-partners-staging',
    storageBucket: 'krugo-partners-staging.appspot.com',
    messagingSenderId: '497221370942',
    appId: '1:497221370942:web:5cc9015bfbb950452c3d6f',
    measurementId: 'G-F42MPLETG4'
  }
};
