<div matRipple
     class="guide-card-container"
     (click)="goToGuide()">
  <app-asset #asset
             class="guide-card"
             [borderRadius]="'1rem'"
             [style.height]="assetHeight|async"
             [asset]="guide.getGuidePhoto()">
  </app-asset>
  <div appFont
       class="guide-card-title"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">
    {{guide?.title}}
  </div>
  <div appFont
       class="guide-by"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.SemiBold">
    {{guide?.guideByStringForCards()}}
  </div>
  <div appFont
       class="view-guide"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.SemiBold"
       (click)="goToGuide()">
    View Guide <img src="/assets/icons/dark/outline/arrow-right.svg" alt="">
  </div>
</div>
