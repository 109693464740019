import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';

@Injectable()
export class FeaturedGuideBannerViewModel extends BaseViewModel {

  constructor(
    private dm: GuidesDomainModel,
  ) {
    super();
  }

}
