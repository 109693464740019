import {BaseViewModel} from '../../../../../../models/base/base-view-model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {OverlayOptions} from '../../../../../../models/shared/overlay-options';
import {GuidesDomainModel} from '../../../../domain/guides-domain-model';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Guide} from '../../../../../../models/shared/guide';
import {Breadcrumb} from '../../breadcrumbs/breadcrumb';
import {InsiderDomainModel} from '../../../../domain/insider-domain-model';

@Injectable()
export class GuideHeaderViewModel extends BaseViewModel {

  public bannerOverlay = new BehaviorSubject<OverlayOptions>(null);
  public breadCrumbs: Breadcrumb[] = [];
  public primaryColor$ = this.insiderDomainModel.primaryColor$;

  constructor(
    private dm: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.initBannerOverlay();
    this.makeBreadCrumbs();
  }

  private initBannerOverlay() {
    const opts = OverlayOptions.default();
    opts.enabled = false;
    this.bannerOverlay.next(opts);
  }

  private makeBreadCrumbs() {
    combineLatest([
      this.dm.insiderId$,
      this.dm.selectedGuide$.notNull()
    ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
      .subscribe(([insiderId, guide]) => {
        this.setupBreadcrumbs(insiderId, guide);
      });
  }

  public setupBreadcrumbs(insiderId: string, guide: Guide) {
    this.breadCrumbs = [];
    const guidesBc = new Breadcrumb(
      'Browse Guides',
      `guides/${insiderId}`,
    );
    this.breadCrumbs.push(guidesBc);
    const guideBc = new Breadcrumb(
      guide.title,
      `guides/${insiderId}/guide/${guide.id}`,
    );
    guideBc.active = true;
    this.breadCrumbs.push(guideBc);
  }

}
