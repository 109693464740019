import { Component, OnInit } from '@angular/core';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-powered-by',
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss']
})
export class PoweredByComponent implements OnInit {

  types = TypeUtils;

  constructor() { }

  ngOnInit(): void {
  }

}
