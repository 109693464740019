export interface Deserializable extends Object {
  onDeserialize();
}

export class DeserializeHelper {

  static deserializeToInstance<T extends Deserializable>(object: new () => T, data: any): T {
    if (!data) {
      return null;
    } else {
      const instance: T = Object.assign(Object.create(object.prototype), data);
      instance.onDeserialize();
      return instance as T;
    }
  }

  static deserializeArray<T extends Deserializable>(object: new () => T, data: any[]): T[] {
    if (!data) {
      return null;
    } else {
      const arr: T[] = [];
      data.forEach(d => {
        const inst: T = Object.assign(Object.create(object.prototype), d);
        inst.onDeserialize();
        arr.push(inst);
      });
      return arr;
    }
  }

  static deserializeGenericMap<X extends string | number, Y>(data: Map<X, Y>): Map<X, Y> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y>();
      const dataMap = new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => {
          newMap.set(key as X, val as Y);
        });
      }
      return newMap;
    }
  }

  static deserializeNestedGenericMap<X extends string | number, Y>(data: Map<X, Map<X, Y>>): Map<X, Map<X, Y>> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Map<X, Y>>();
      const dataMap = new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => {
          newMap.set(key as X, DeserializeHelper.deserializeGenericMap(val));
        });
      }
      return newMap;
    }
  }

  static deserializeGenericArrayMap<X extends string | number, Y extends string | number>(data: Map<X, Y[]>): Map<X, Y[]> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      const dataMap = new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => {
          newMap.set(key as X, val as Y[]);
        });
      }
      return newMap;
    }
  }

  static deserializeTypedMap<X extends string | number, Y extends Deserializable>(objectVal: new () => Y, data: Map<X, Y>): Map<X, Y> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y>();
      const dataMap = new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => {
          newMap.set(key as X, this.deserializeToInstance(objectVal, val));
        });
      }
      return newMap;
    }
  }

  static deserializeTypedArrayMap<X extends string | number, Y extends Deserializable>(objectVal: new () => Y, data: Map<X, Y[]>): Map<X, Y[]> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      const dataMap = new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => {
          newMap.set(key as X, this.deserializeArray(objectVal, val));
        });
      }
      return newMap;
    }
  }

}
