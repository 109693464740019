import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SwiperOptions} from 'swiper';
import {PaginationOptions} from 'swiper/types';
import {PaginatedGuides} from '../../../../../models/shared/paginated-guides';
import ResizeObserver from 'resize-observer-polyfill';
import {SwiperComponent} from 'ngx-swiper-wrapper';
import {BaseComponent} from '../../../../../models/base/base-component';

@Component({
  selector: 'app-guides-paginated',
  templateUrl: './guides-paginated.component.html',
  styleUrls: ['./guides-paginated.component.scss']
})
export class GuidesPaginatedComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() paginatedGuides: PaginatedGuides[] = [];
  private pagination: PaginationOptions = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
  };
  private defaultConfig: SwiperOptions = {
    a11y: { enabled: true },
    autoHeight: false,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    updateOnWindowResize: true,
  };
  config: SwiperOptions = this.defaultConfig;
  //
  @ViewChild('container')
  private container: ElementRef;
  @ViewChild('swiper')
  public swiper: SwiperComponent;
  private observeContainer: ResizeObserver;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setPagination();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.observeSwiperContainer();
  }

  observeSwiperContainer() {
    this.observeContainer = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        window.dispatchEvent(new Event('resize'));
      }
    });
    this.observeContainer.observe(this.container.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginatedGuides) {
      this.setPagination();
    }
  }

  setPagination() {
    this.config.navigation = ((this.paginatedGuides?.length ?? 0) > 1);
    this.config.pagination = (this.paginatedGuides?.length > 1) ? this.pagination : false;
  }

  ngOnDestroy(): void {
    this.destroy();
    this.observeContainer.disconnect();
  }

}
