import {Asset} from '../dto/asset';
import {AssetSize} from '../../enum/dto/asset-size.enum';

export class FullImage extends Asset {
  onDeserialize() {
    // on refresh asset, we still only want to pull in the sizes we would use
    this.sizeOverrides = [AssetSize.Medium, AssetSize.Large];
    super.onDeserialize();
  }
}
