<div class="guide-header-container"
     id="guide-header-container">
  <app-swiper-carousel class="banner"
                       [overlay]="(bannerOverlay | async)"
                       [assets]="guide?.images">
  </app-swiper-carousel>
  <app-breadcrumbs
    [breadcrumbs]="vm.breadCrumbs">
  </app-breadcrumbs>
  <div appFont
       class="guide-title"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">{{guide?.title}}</div>
  <div appFont
       class="guide-desc"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Regular">
    {{guide?.description}}
  </div>
  <div class="guide-insider-container">
    <app-guide-created-by class="guide-insider"
                          [guide]="guide">
    </app-guide-created-by>
    <button class="custom-button guide-share-button"
            appFont
            [type]="types.FontType.SemiBold"
            [hierarchy]="types.FontHierarchy.Primary"
            [popperPlacement]="'top-end'"
            [style.background-color]="vm.primaryColor$|async"
            [popper]="popperContent">
      Share
      <img src="/assets/icons/light/outline/export.svg" alt="">
    </button>
    <popper-content #popperContent>
      <div class="share-options-list">
        <div class="share-options-list-item"
             appFont
             [hierarchy]="types.FontHierarchy.Primary"
             (click)="$event.stopPropagation(); copyLink(); popperContent.hide()">
          Copy Link
        </div>
        <div class="share-options-list-item"
             appFont
             [hierarchy]="types.FontHierarchy.Primary"
             (click)="$event.stopPropagation(); shareToSocial(); popperContent.hide()">
          Share to Social
        </div>
      </div>
    </popper-content>
  </div>
</div>
