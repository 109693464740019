<div matRipple class="insider-container" fxLayout="row" fxLayoutAlign="stretch center">
  <div class="insider-image" fxFlex="none">
    <app-asset class="asset"
               [borderRadius]="'50%'"
               [asset]="insider?.profilePicture">
    </app-asset>
  </div>
  <div class="insider-name"
       appFont
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.SemiBold">
    {{insider?.getFullName()}}
  </div>
</div>
