import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Guide} from '../../../../../models/shared/guide';
import {BaseComponent} from '../../../../../models/base/base-component';
import {TypeUtils} from '../../../utils/type-utils';
import {Insider} from '../../../../../models/shared/insider';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InsiderModalComponent} from '../insider-modal/insider-modal.component';

@Component({
  selector: 'app-guide-created-by',
  templateUrl: './guide-created-by.component.html',
  styleUrls: ['./guide-created-by.component.scss']
})
export class GuideCreatedByComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guide: Guide;
  types = TypeUtils;

  constructor(
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  openInsider(insider: Insider) {
    const modalRef = this.modalService.open(
      InsiderModalComponent,
      ModalUtils.insiderModalOptions()
    );
    (modalRef.componentInstance as InsiderModalComponent).guide = this.guide;
    (modalRef.componentInstance as InsiderModalComponent).insider = insider;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
