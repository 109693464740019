<div class="map-container">

  <app-icon-generator *ngFor="let m of mapMarkers; let i = index"
                      [mapMarker]="m"
                      [font]="vm.primaryFont$|async"
                      [fillColor]="vm.primaryColor$|async"
                      [selectedMarker]="vm.selectedMarker$|async"
                      (src)="vm.addSrcUrl($event, m)"
                      [index]="i+1">
  </app-icon-generator>

  <agm-map #map
           class="map"
           [usePanning]="vm.usePanning$|async"
           [fitBoundsPadding]="vm.boundsPadding$|async"
           [styles]="getMapStyling()"
           [disableDefaultUI]="vm.disableDefaultUI$|async"
           (mapReady)="mapRdy($event)"
           [zoom]="defaultZoomLevel"
           (mapClick)="mapClicked($event)">
    <agm-marker *ngFor="let m of mapMarkers; let i = index"
                (markerClick)="markerClicked(m, i)"
                [latitude]="m?.getLat()"
                [agmFitBounds]="true"
                [longitude]="m?.getLng()"
                [zIndex]="m.getUniqueId() === (vm.selectedMarker$|async)?.id ? 1 : 0"
                [iconUrl]="(vm.urlSrcMap$|async).get(m.getUniqueId())"
                [markerDraggable]="false">
    </agm-marker>
  </agm-map>

  <div *ngIf="!hideCards"
       class="horizontal-card-scroller"
       appCardSnapScroller
       [select]="vm.selectedMarker$|async"
       [cardsAreInFrame]="vm.usePanning$|async"
       (selected)="currentVisibleCard($event)">
    <app-map-card *ngFor="let marker of mapMarkers; let i = index; let last = last;"
                  (click)="guideCardClicked(marker)"
                  [index]="i+1"
                  [mapMarker]="marker"
                  [last]="last"
                  [multipleCards]="mapMarkers.length > 1">
    </app-map-card>
  </div>

</div>
