import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UrlUtils} from '../../../../utils/url-utils';
import {GuideClickType} from '../../../../models/guides/add-guide-view-req';
import {TypeUtils} from '../../../../modules/krugo-guides/utils/type-utils';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {

  @Input() facebookUrl: string;
  @Input() instagramUrl: string;
  @Input() twitterUrl: string;
  @Input() smallIcons: boolean = false;
  @Output() socialClick = new EventEmitter<GuideClickType>();
  public types = TypeUtils;

  constructor() {
  }

  ngOnInit(): void {
  }

  hasSocials(): boolean {
    return !!this.facebookUrl || !!this.instagramUrl || !!this.twitterUrl;
  }

  handleLinkClick(t: GuideClickType) {
    this.socialClick.emit(t);
  }

  getFormattedFacebookUrl(): string {
    return UrlUtils.formatUrl(this.facebookUrl);
  }

  getFormattedInstagramUrl(): string {
    return UrlUtils.formatUrl(this.instagramUrl);
  }

  getFormattedTwitterUrl(): string {
    return UrlUtils.formatUrl(this.twitterUrl);
  }

}
