import {MediaUtils} from '../../utils/media-utils';
import {AssetSize} from '../enum/dto/asset-size.enum';
import {Guide} from './guide';

export class MinimalHydratedGuide extends Guide {
  onDeserialize() {
    this.loadMinimalImages = true;
    this.images?.forEach(i => {
      if (MediaUtils.isVideo(i.mediaType)) {
        i.sizeOverrides = [AssetSize.Large];
      } else {
        i.sizeOverrides = [AssetSize.Medium];
      }
    });
    super.onDeserialize();
  }
}
