<div class="guide-container">
  <app-guide-header [guide]="guide">
    Guide Header
  </app-guide-header>
  <app-guide-section *ngFor="let section of guide?.sections"
                     [section]="section"
                     [selectedMapMarker]="selectedMapMarker"
                     [guide]="guide">
    Guide Section
  </app-guide-section>
</div>
