import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KrugoMapMarker} from '../interfaces/krugo-map-marker';
import {distinctUntilChanged} from 'rxjs/operators';
import {DistinctUtils} from '../../../../../../utils/distinct.utils';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private selectedMarker = new BehaviorSubject<KrugoMapMarker | null>(null);
  public selectedMarker$ = this.selectedMarker.pipe(distinctUntilChanged(DistinctUtils.distinctKrugoMapMarker));

  constructor() {}

  changeSelectedMapMarker(m: KrugoMapMarker | null) {
    this.selectedMarker.next(m);
  }

}
