import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppAsideModule, AppSidebarModule} from '@coreui/angular';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthInterceptorInterceptor} from './services/interceptors/auth-interceptor.interceptor';
import {SharedModule} from './views/shared/shared.module';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './services/strategy/cache-route-reuse.strategy';
import {KrugoGuidesModule} from './modules/krugo-guides/krugo-guides.module';
import {AgmCoreModule} from '@agm/core';
import {HomeComponent} from './modules/krugo-guides/components/main/home/home.component';
import {FlexModule} from '@angular/flex-layout';
import {environment} from '../environments/environment';
import {NgxPopperModule} from 'ngx-popper';

const APP_CONTAINERS = [
  HomeComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppSidebarModule,
    AppAsideModule,
    HttpClientModule,
    KrugoGuidesModule,
    BsDropdownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey, libraries: ['places']
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'custom-toast ngx-toastr'
    }),
    NgxPopperModule.forRoot({
      applyClass: 'custom-popper-content',
      applyArrowClass: 'd-none',
      hideOnClickOutside: true,
      hideOnScroll: true,
      preventOverflow: false,
      positionFixed: true,
      trigger: 'click',
      placement: 'bottom-end',
      styles: {
        'background-color': '#FFFFFF'
      },
      popperModifiers: {
        flip: {
          behavior: ['right', 'bottom', 'top']
        }
      }
    }),
    SharedModule,
    FlexModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
