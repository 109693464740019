import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {KrugoMapMarker} from '../../interfaces/krugo-map-marker';
import {TypeUtils} from '../../../../../utils/type-utils';

@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.scss']
})
export class MapCardComponent implements OnInit {

  @Input() mapMarker: KrugoMapMarker;
  @Input() multipleCards: boolean = false;
  @Input() last: boolean = false;
  @Input() index: number;
  types = TypeUtils;

  constructor(
    public el: ElementRef,
  ) { }

  ngOnInit(): void {
  }

}
