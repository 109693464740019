import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  static MOBILE_WIDTH = 768;
  public isMobile = new BehaviorSubject<boolean>(false);
  public publishScrollPosition = new BehaviorSubject<number>(null);
  public publishIFrameHeight = new BehaviorSubject<boolean>(null);

  constructor() {}

  setIsMobile(x: boolean) {
    this.isMobile.next(x);
  }

  public connectToIsMobile(): Observable<boolean> {
    return this.isMobile.asObservable().pipe(distinctUntilChanged());
  }
}
