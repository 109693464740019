import {Deserializable} from '../protocols/deserializable';

export class AddGuideViewResponse implements Deserializable {
  public clickType: string;
  public guideId: string;
  public timestamp: number;
  public viewerId: string;

  onDeserialize() {
  }
}
