import {BehaviorSubject, Subject} from 'rxjs';
import {OverlayOptions} from '../../../../../../../models/shared/overlay-options';
import {BaseViewModel} from '../../../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../../domain/guides-domain-model';
import {Injectable} from '@angular/core';
import {ScreenService} from '../../../../../../../services/screen.service';
import {KrugoMapMarker} from '../../interfaces/krugo-map-marker';
import {MapService} from '../../services/map.service';
import {DrawerService} from '../../../../../../../services/drawer.service';
import {InsiderDomainModel} from '../../../../../domain/insider-domain-model';
import {GuideClickType} from '../../../../../../../models/guides/add-guide-view-req';

@Injectable()
export class MapItemDetailsViewModel extends BaseViewModel {

  public bannerOverlay = new BehaviorSubject<OverlayOptions>(null);
  public primaryColor$ = this.insiderDomainModel.primaryColor$;
  public isMobile$ = this.screenService.connectToIsMobile();
  public closeModal = new Subject<boolean>();

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private screenService: ScreenService,
    private mapService: MapService,
    private drawerService: DrawerService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.initBannerOverlay();
  }

  private initBannerOverlay() {
    const opts = OverlayOptions.default();
    opts.enabled = false;
    this.bannerOverlay.next(opts);
  }

  viewOnMap(item: KrugoMapMarker) {
    this.mapService.changeSelectedMapMarker(item);
    this.drawerService.closeDrawer();
    this.closeModal.next(true);
  }

  addView(type: GuideClickType) {
    const guideId = this.guidesDomainModel.guideId.getValue();
    this.insiderDomainModel.addSocialView(guideId, type);
  }
}
