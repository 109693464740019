
<div matRipple class="card"
     [ngClass]="this.multipleCards ? 'multi' : 'single'"
     [style.margin-right]="!last ? '16px' : ''">
  <div class="krugo-card-layout">
    <app-asset [style.object-fit]="'cover'"
               [borderRadius]="'4px'"
               [asset]="mapMarker?.getCoverImage()"
               fxFlex="none"
               class="asset"></app-asset>
    <div class="text-container">
      <div appFont
           class="guide-title"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Bold">
        {{index}}. {{mapMarker?.getTitle()}}
      </div>
      <div appFont
           class="guide-desc"
           [hierarchy]="types.FontHierarchy.Secondary"
           [type]="types.FontType.Regular">
        {{mapMarker?.getDesc()}}
      </div>
    </div>
  </div>
</div>
