export class AddGuideViewReq {
  public clickType: GuideClickType;
  public guideId: string;
  public timestamp: number;
  public viewerId: string;

  constructor(
    clickType: GuideClickType,
    guideId: string,
    timestamp: number,
    viewerId: string
  ) {
    this.clickType = clickType;
    this.guideId = guideId;
    this.timestamp = timestamp;
    this.viewerId = viewerId;
  }
}

export enum GuideClickType {
  GUIDE = 'GuideClickType_Guide',
  FACEBOOK = 'GuideClickType_Facebook',
  INSTAGRAM = 'GuideClickType_Instagram',
  TWITTER = 'GuideClickType_Twitter',
  WEBSITE = 'GuideClickType_Website',
}
