import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {DrawerService} from '../../../../../services/drawer.service';
import {BrowseGuidesViewModel} from './browse-guides-view-model';
import {ScreenService} from '../../../../../services/screen.service';

@Component({
  selector: 'app-browse-guides',
  templateUrl: './browse-guides.component.html',
  styleUrls: ['./browse-guides.component.scss'],
  providers: [BrowseGuidesViewModel]
})
export class BrowseGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public loadingOpts$ = this.vm.loadingOpts$;

  constructor(
    public vm: BrowseGuidesViewModel,
    private drawerService: DrawerService,
    private screenService: ScreenService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit() {
    this.drawerService.enteredGuidesView();
    this.setupBindings();

    setTimeout(() => {
      this.screenService.publishIFrameHeight.next(true);
    }, 250);
  }

  setupBindings() {
  }

  ngOnDestroy() {
    this.destroy();
  }

}
