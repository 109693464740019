import { Component, OnInit } from '@angular/core';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  types = TypeUtils;

  constructor() { }

  ngOnInit(): void {
  }

}
