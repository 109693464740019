<div id="banner-container" class="banner-container">
  <app-swiper-carousel class="banner"
                       [assets]="guide?.images">
    <div class="text-container">
      <div appFont
           class="featured-guide-text"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Bold"
           (click)="goToGuide()">
        Featured Guide
      </div>
      <div appFont
           class="featured-guide-name"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Bold"
           (click)="goToGuide()">
        {{guide?.title}}
      </div>
      <div appFont
           class="view-guide"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.SemiBold"
           (click)="goToGuide()">
        View Guide <img src="/assets/icons/light/outline/arrow-right.svg" alt="">
      </div>
    </div>
  </app-swiper-carousel>
</div>
