import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Asset} from '../image/dto/asset';
import {Coordinates} from './coordinates';
import {KrugoMapMarker} from '../../modules/krugo-guides/components/sub/map/interfaces/krugo-map-marker';
import {UrlUtils} from '../../utils/url-utils';

export class Place implements KrugoMapMarker, Deserializable {
  public address: string;
  public categoryIds: string[];
  public city: string;
  public coordinates: Coordinates;
  public countryCode: string;
  public createdAt: number;
  public facebookAccount: string;
  public foursquareUrl: string;
  public instagramAccount: string;
  public lastUpdated: number;
  public likeCount: number;
  public priceTier: number;
  public mainPhoto: Asset[];
  public name: string;
  public phoneNumber: string;
  public placeId: string;
  public postalCode: string;
  public rating: number;
  public ratingColor: string;
  public stateCode: string;
  public twitterAccount: string;
  public url: string;
  // Not from api
  public desc: string;
  public images: Asset[] = [];

  initDesc(d: string) {
    this.desc = d;
  }

  onDeserialize() {
    this.categoryIds = Array.from(this.categoryIds ?? []);
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.mainPhoto = DeserializeHelper.deserializeArray(Asset, this.mainPhoto) ?? [];
    this.images = [];
  }

  /** Krugo Map Marker Interface */

  getDesc(): string {
    return this.desc;
  }

  getLat(): number {
    return this.coordinates.lat;
  }

  getLng(): number {
    return this.coordinates.lng;
  }

  getTitle(): string {
    return this.name;
  }

  getUniqueId(): string {
    return this.placeId;
  }

  getCoverImage(): Asset {
    if (this.images.length > 0) {
      return this.images[0];
    }
    return undefined;
  }

  getImages(): Asset[] {
    return this.images;
  }

  getUrl(): string {
    return UrlUtils.formatUrl(this.url);
  }

  hasSocials(): boolean {
    return false;
  }

  getFacebookUrl(): string {
    return null;
  }

  getInstagramUrl(): string {
    return null;
  }

  getTwitterUrl(): string {
    return null;
  }
}
