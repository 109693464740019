import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgmCoreModule} from '@agm/core';
import {KrugoGuidesRoutingModule} from './krugo-guides-routing.module';
import {SharedModule} from '../../views/shared/shared.module';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {FlexModule} from '@angular/flex-layout';
import {MapComponent} from './components/sub/map/map.component';
import {FeaturedGuideBannerComponent} from './components/sub/featured-guide-banner/featured-guide-banner.component';
import {SwiperCarouselComponent} from './components/sub/swiper-carousel/swiper-carousel.component';
import {BrowseGuideComponent} from './components/main/browse-guide/browse-guide.component';
import {BrowseGuidesComponent} from './components/main/browse-guides/browse-guides.component';
import {RecentGuidesComponent} from './components/sub/guides-recent/recent-guides.component';
import {GuideCardComponent} from './components/sub/guide-card/guide-card.component';
import {FontDirective} from './directives/font.directive';
import {GuideSearchComponent} from './components/sub/guide-search/guide-search.component';
import {SearchBarComponent} from './components/sub/search-bar/search-bar.component';
import {FilterGuidesComponent} from './components/sub/guides-filter/filter-guides.component';
import {PillComponent} from './components/sub/pills/pill/pill.component';
import {PillFilterComponent} from './components/sub/pills/pill-filter/pill-filter.component';
import {GuidesGridComponent} from './components/sub/guides-grid/guides-grid.component';
import {GuidesPaginatedComponent} from './components/sub/guides-paginated/guides-paginated.component';
import {EmptyStateComponent} from './components/sub/empty-state/empty-state.component';
import {PoweredByComponent} from './components/sub/powered-by/powered-by.component';
import {GuideHeaderComponent} from './components/sub/guide/guide-header/guide-header.component';
import {GuideSectionComponent} from './components/sub/guide/guide-section/guide-section.component';
import {PageNotFoundComponent} from './components/main/page-not-found/page-not-found.component';
import {GuideSectionItemComponent} from './components/sub/guide/guide-section/guide-section-item/guide-section-item.component';
import {GuideComponent} from './components/sub/guide/guide.component';
import {LoadingComponent} from './components/sub/loading/loading.component';
import {MatIconModule} from '@angular/material/icon';
import {MapFabComponent} from './components/sub/map-fab/map-fab.component';
import {CardSnapScrollerDirective} from './components/sub/map/directives/card-snap-scroller.directive';
import {IconGeneratorComponent} from './components/sub/map/components/icon-generator/icon-generator.component';
import {MapCardComponent} from './components/sub/map/components/map-card/map-card.component';
import {MadeByComponent} from './components/sub/made-by/made-by.component';
import {InsiderComponent} from './components/sub/insider/insider.component';
import {GuideCreatedByComponent} from './components/sub/guide-created-by/guide-created-by.component';
import {MapItemDetailsComponent} from './components/sub/map/components/map-item-details/map-item-details.component';
import {BreadcrumbsComponent} from './components/sub/breadcrumbs/breadcrumbs.component';
import { InsiderModalComponent } from './components/sub/insider-modal/insider-modal.component';
import { GuidesFeatureComponent } from './components/sub/guides-feature/guides-feature.component';
import { GuideFeatureCardComponent } from './components/sub/guide-feature-card/guide-feature-card.component';
import {NgxPopperModule} from 'ngx-popper';
import {ShareToSocialModalComponent} from './components/sub/share-to-social-modal/share-to-social-modal.component';


@NgModule({
  declarations: [
    BreadcrumbsComponent,
    MapComponent,
    BrowseGuidesComponent,
    BrowseGuideComponent,
    FeaturedGuideBannerComponent,
    SwiperCarouselComponent,
    RecentGuidesComponent,
    GuideCardComponent,
    FontDirective,
    GuideComponent,
    GuideSearchComponent,
    SearchBarComponent,
    FilterGuidesComponent,
    PillComponent,
    PillFilterComponent,
    GuidesGridComponent,
    GuidesPaginatedComponent,
    EmptyStateComponent,
    PoweredByComponent,
    GuideHeaderComponent,
    GuideSectionComponent,
    PageNotFoundComponent,
    GuideSectionItemComponent,
    LoadingComponent,
    MapFabComponent,
    MapCardComponent,
    CardSnapScrollerDirective,
    IconGeneratorComponent,
    MadeByComponent,
    InsiderComponent,
    GuideCreatedByComponent,
    MapItemDetailsComponent,
    InsiderModalComponent,
    GuidesFeatureComponent,
    GuideFeatureCardComponent,
    ShareToSocialModalComponent
  ],
  exports: [
    MapComponent,
    SwiperCarouselComponent,
    LoadingComponent,
    MapFabComponent,
    FontDirective,
  ],
  imports: [
    KrugoGuidesRoutingModule,
    CommonModule,
    AgmCoreModule,
    SharedModule,
    SwiperModule,
    FlexModule,
    MatIconModule,
    NgxPopperModule,
  ]
})
export class KrugoGuidesModule { }
