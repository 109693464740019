import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {Injectable} from '@angular/core';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {ActivatedRoute, Params} from '@angular/router';
import {map, takeUntil, tap} from 'rxjs/operators';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../../models/enum/shared/loading-spinner-size.enum';
import {MapService} from '../../sub/map/services/map.service';

@Injectable()
export class BrowseGuideViewModel extends BaseViewModel {

  public loadingOpts = this.getLoadingOptions(false);
  public selectedGuide = this.dm.selectedGuide$;
  public selectedMapMarker$ = this.mapService.selectedMarker$;

  // Listeners
  private listenToActivatedRoute = this.activatedRoute.params
    .pipe(takeUntil(this.onDestroy))
    .subscribe(p => {
      this.initFromUrl(p);
    });

  private listenToLoading = this.dm.selectedGuideLoading$
    .pipe(takeUntil(this.onDestroy))
    .subscribe(loading => {
      this.loadingOpts.isLoading = loading;
    });

  constructor(
    private dm: GuidesDomainModel,
    private mapService: MapService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  private initFromUrl(p: Params) {
    const guideId = p.guideId;
    if (guideId) {
      this.dm.setGuideId(guideId);
    }
  }

  private getLoadingOptions(loading: boolean): LoadingOptions {
    const x = LoadingOptions.default(false, false);
    x.isLoading = loading;
    x.spinnerColor = '#000000';
    x.spinnerSize = LoadingSpinnerSize.Medium;
    x.color = '#000000';
    return x;
  }

}
