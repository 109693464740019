import {FontHierarchy} from '../enum/font-hierarchy.enum';
import {FontType} from '../enum/font-type.enum';
import {GuideClickType} from '../../../models/guides/add-guide-view-req';
import {AssetSize} from '../../../models/enum/dto/asset-size.enum';

export class TypeUtils {
  static FontHierarchy: typeof FontHierarchy = FontHierarchy;
  static FontType: typeof FontType = FontType;
  static GuideClickType: typeof GuideClickType = GuideClickType;
  static AssetSize: typeof AssetSize = AssetSize;
}
