import {Deserializable} from '../../protocols/deserializable';

export class User implements Deserializable {
  public userId: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public hasProfilePicture: boolean;

  public onDeserialize() {

  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }
}
