import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ComponentCanDeactivate} from '../../models/protocols/component-can-deactivate';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private modalService: NgbModal
  ) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<any> {
    return component.canDeactivate();
    if (CanDeactivateState.defendAgainstBrowserBackButton) {
      history.pushState(null, '', '');
    }
  }

}
