import { Injectable } from '@angular/core';
import {CacheService} from './cache-service';
import {UUID} from '../models/shared/uuid';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UUIDService {

  constructor(
    private cacheService: CacheService,
  ) {
  }

  static generateUUID(): string {
    return uuidv4();
  }

  getUUID(): UUID {
    const cacheKey = UUID.buildCacheKey();
    const cachedUUID = this.cacheService.getCachedObject<UUID>(UUID, cacheKey);
    if (cachedUUID) {
      return cachedUUID;
    } else {
      const id = UUIDService.generateUUID();
      const uid = new UUID();
      uid.id = id;
      this.cacheService.cacheObject<UUID>(uid.cacheKey(), uid);
      return uid;
    }
  }

}
