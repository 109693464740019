import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {Guide} from '../../../../../models/shared/guide';
import {PillItem} from '../../../../../models/shared/stylesheet/pill-item';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import FuzzySearch from 'fuzzy-search';
import {PaginatedGuides} from '../../../../../models/shared/paginated-guides';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {DistinctUtils} from '../../../../../utils/distinct.utils';

@Injectable()
export class GuideSearchViewModel extends BaseViewModel {

  public guides = new BehaviorSubject<Guide[]>([]);
  public searchPills = this.guides.pipe(
    map(guides => {
      if (!!guides) {
        const tagStrings = new Set(guides?.flatMap(guide => guide.tags));
        const tags = [];
        tagStrings.forEach(tag => tags.push(new PillItem(tag)));
        return tags;
      } else {
        return [];
      }
    }),
  );
  public searchString = new BehaviorSubject<string>('');
  public filters = new BehaviorSubject<string[]>([]);
  // Filter
  private filteredGuides = combineLatest([
    this.guides,
    this.searchString,
    this.filters,
  ]).pipe(
    debounceTime(250),
    map(([guides, searchText, filters]) => {
      let filteredGuides: Guide[] = guides;
      if (!!filters && filters.length > 0) {
        filteredGuides = filteredGuides.filter(guide => {
          return guide.tags.map(tag => tag.trim().toLowerCase()).intersection(filters)?.length > 0;
        });
      }
      if (searchText) {
        const searcher = new FuzzySearch(filteredGuides, ['title'], {caseSensitive: false});
        filteredGuides = searcher.search(searchText);
      }
      return filteredGuides;
    }),
    distinctUntilChanged(DistinctUtils.distinctGuides)
  );
  // Paginated Guides
  public paginatedGuides = this.filteredGuides.pipe(map(guides => {
    const nGuidesPerPage: number = 9;
    const nPages = Math.ceil(guides?.length / nGuidesPerPage);
    const pages: PaginatedGuides[] = [];
    for (let i = 1; i <= nPages; i++) {
      const startIndex = ((i - 1) * nGuidesPerPage);
      const endIndex = (i * nGuidesPerPage);
      pages.push(new PaginatedGuides(guides.slice(startIndex, endIndex)));
    }
    return pages;
  }));

  constructor(
    private dm: GuidesDomainModel
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
