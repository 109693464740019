<div class="grid-container">
  <div *ngFor="let guide of guides"
       matRipple
       class="guide-container"
       (click)="onGuideClick(guide)">
    <app-asset class="asset"
               fxFlex="none"
               [asset]="guide.getGuidePhoto()"
               [style.object-fit]="'cover'"
               [borderRadius]="'10px'">
    </app-asset>
    <div class="text-container">
      <div appFont
           class="guide-title"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Bold">
        {{guide?.title}}
      </div>
      <div appFont
           class="guide-desc"
           [hierarchy]="types.FontHierarchy.Secondary"
           [type]="types.FontType.Regular">
        {{guide?.description}}
      </div>
    </div>
  </div>
</div>

