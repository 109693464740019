import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GuideSection} from '../../../../../../models/shared/guide-section';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {Guide} from '../../../../../../models/shared/guide';
import {TypeUtils} from '../../../../utils/type-utils';
import {KrugoMapMarker} from '../../map/interfaces/krugo-map-marker';

@Component({
  selector: 'app-guide-section',
  templateUrl: './guide-section.component.html',
  styleUrls: ['./guide-section.component.scss']
})
export class GuideSectionComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: Guide;
  @Input() section: GuideSection;
  @Input() selectedMapMarker: KrugoMapMarker;
  types = TypeUtils;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
