import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DndDirective} from './directives/dnd-directive';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SegmentedControlComponent} from './components/segmented-control/segmented-control.component';
import {AssetComponent} from './components/asset/asset.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {DragScrollDirective} from './directives/drag-scroll.directive';
import {NgxShimmerLoadingModule} from 'ngx-shimmer-loading';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import {FlexModule} from '@angular/flex-layout';
import { SocialLinksComponent } from './components/social-links/social-links.component';


@NgModule({
  providers: [],
  declarations: [
    AssetComponent,
    DndDirective,
    SegmentedControlComponent,
    ProgressBarComponent,
    DragScrollDirective,
    AlertBannerComponent,
    SocialLinksComponent,
  ],
    imports: [
        CommonModule,
        MaterialModule,
        NgxSpinnerModule,
        ColorPickerModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        NgbTooltipModule,
        NgxShimmerLoadingModule,
        FlexModule,
    ],
    exports: [
        // Shared Components
        MaterialModule,
        NgxSpinnerModule,
        ColorPickerModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        AssetComponent,
        SegmentedControlComponent,
        ProgressBarComponent,
        DragScrollDirective,
        AlertBannerComponent,
        SocialLinksComponent
    ],
})
export class SharedModule {
}
