import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Guide} from '../models/shared/guide';
import {GuidesDomainModel} from '../modules/krugo-guides/domain/guides-domain-model';
import {combineLatest, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subscribable} from '../models/base/subscribable';
import {MapService} from '../modules/krugo-guides/components/sub/map/services/map.service';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService extends Subscribable
  implements OnDestroy {

  private goToGuide = new Subject<string>();
  private navigator = combineLatest([
    this.dm.insiderId$.pipe(distinctUntilChanged()),
    this.goToGuide.asObservable()
  ]).pipe(
    takeUntil(this.onDestroy),
    debounceTime(1)
  ).subscribe(([insiderId, goToGuide]) => {
    if (insiderId) {
      if (goToGuide) {
        this.router.navigate([`/guides/${insiderId}/guide/${goToGuide}`]).then();
      } else {
        this.mapService.changeSelectedMapMarker(null);
        this.router.navigate([`/guides/${insiderId}`]).then();
      }
    } else {
      this.router.navigate([`/404`]).then();
    }
  });

  constructor(
    private router: Router,
    private dm: GuidesDomainModel,
    private mapService: MapService,
  ) {
    super();
  }

  toGuide(g: Guide) {
    if (g) {
      this.goToGuide.next(g.id);
      this.dm.addGuideView(g);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
