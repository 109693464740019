import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Guide} from '../../../../../models/shared/guide';
import {BaseComponent} from '../../../../../models/base/base-component';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-recent-guides',
  templateUrl: './recent-guides.component.html',
  styleUrls: ['./recent-guides.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guides: Guide[] = [];
  types = TypeUtils;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
