import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ScreenService} from './screen.service';
import {Subscribable} from '../models/base/subscribable';

@Injectable({
  providedIn: 'root'
})
export class DrawerService extends Subscribable
  implements OnDestroy {

  private drawerWidth = new BehaviorSubject<DrawerWidth>(DrawerWidth.HALF);
  private currentScreen = new BehaviorSubject<DrawerScreen>(DrawerScreen.BROWSE_GUIDES);

  // When screen change occurs, fix error states.
  private screenSizeFixer = combineLatest([
    this.screenService.connectToIsMobile(),
    this.currentScreen,
    this.drawerWidth
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([isMobile, currentScreen, width]) => {
      if (isMobile) {
        switch (currentScreen) {
          case DrawerScreen.BROWSE_GUIDES: {
            switch (width) {
              case DrawerWidth.CLOSED:  this.makeDrawerFull(); break;
              case DrawerWidth.HALF:    this.makeDrawerFull(); break;
              case DrawerWidth.FULL:    break;
            }
            break;
          }
          case DrawerScreen.GUIDE: {
            switch (width) {
              case DrawerWidth.CLOSED:  break;
              case DrawerWidth.HALF:    this.makeDrawerFull(); break;
              case DrawerWidth.FULL:    break;
            }
            break;
          }
        }
      } else {
        switch (currentScreen) {
          case DrawerScreen.BROWSE_GUIDES: {
            switch (width) {
              case DrawerWidth.CLOSED:  this.makeDrawerFull(); break;
              case DrawerWidth.HALF:    break;
              case DrawerWidth.FULL:    break;
            }
            break;
          }
          case DrawerScreen.GUIDE: {
            switch (width) {
              case DrawerWidth.CLOSED:  this.makeDrawerHalf(); break;
              case DrawerWidth.HALF:    break;
              case DrawerWidth.FULL:    this.makeDrawerHalf(); break;
            }
            break;
          }
        }
      }
    });

  // Drawer Toggler
  private toggleSubject = new BehaviorSubject<boolean>(false);
  private toggler = combineLatest([
    this.screenService.connectToIsMobile(),
    this.drawerWidth,
    this.toggleSubject
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([isMobile, width, toggle]) => {
      if (toggle) {
        if (isMobile) {
          switch (width) {
            case DrawerWidth.CLOSED:  this.openDrawer(); break;
            case DrawerWidth.HALF:    break;
            case DrawerWidth.FULL:    this.closeDrawer(); break;
          }
        } else {
          switch (width) {
            case DrawerWidth.CLOSED:  this.openDrawer(); break;
            case DrawerWidth.HALF:    this.closeDrawer(); break;
            case DrawerWidth.FULL:    break;
          }
        }
        this.toggleSubject.next(false);
      }
    });

  // Change drawer state
  private changeDrawerSubject = new BehaviorSubject<ChangeDrawerStatus>(ChangeDrawerStatus.HOLD);
  private openAndCloser = combineLatest([
    this.screenService.connectToIsMobile(),
    this.changeDrawerSubject
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([isMobile, status]) => {
      if (isMobile) {
        switch (status) {
          case ChangeDrawerStatus.HOLD: break;
          case ChangeDrawerStatus.OPEN: {
            this.makeDrawerFull();
            this.changeDrawerSubject.next(ChangeDrawerStatus.HOLD);
            break;
          }
          case ChangeDrawerStatus.CLOSE: {
            this.makeDrawerNone();
            this.changeDrawerSubject.next(ChangeDrawerStatus.HOLD);
            break;
          }
        }
      } else {
        switch (status) {
          case ChangeDrawerStatus.HOLD: break;
          case ChangeDrawerStatus.OPEN: {
            this.makeDrawerHalf();
            this.changeDrawerSubject.next(ChangeDrawerStatus.HOLD);
            break;
          }
          case ChangeDrawerStatus.CLOSE: {
            this.makeDrawerNone();
            this.changeDrawerSubject.next(ChangeDrawerStatus.HOLD);
            break;
          }
        }
      }
      this.publishScreenPosition();
    });

  // Entered guides view
  private enteredGuidesViewSubject = new BehaviorSubject<boolean>(false);
  private setDrawerForGuidesView = combineLatest([
    this.screenService.connectToIsMobile(),
    this.enteredGuidesViewSubject,
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([_, entered]) => {
      if (entered) {
        this.makeDrawerFull();
        this.enteredGuidesViewSubject.next(false);
        this.currentScreen.next(DrawerScreen.BROWSE_GUIDES);
      }
    });

  // Entered into guide view
  private enteredGuideViewSubject = new BehaviorSubject<boolean>(false);
  private setDrawerForGuideView = combineLatest([
    this.screenService.connectToIsMobile(),
    this.enteredGuideViewSubject,
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([isMobile, entered]) => {
      if (entered) {
        if (isMobile) {
          this.makeDrawerFull();
        } else {
          this.makeDrawerHalf();
        }
        this.enteredGuideViewSubject.next(false);
        this.currentScreen.next(DrawerScreen.GUIDE);
      }
    });

  constructor(
    private screenService: ScreenService,
  ) {
    super();
  }

  publishScreenPosition() {
    setTimeout(() => {
      this.screenService.publishScrollPosition.next(100);
    }, 100);
    setTimeout(() => {
      this.screenService.publishIFrameHeight.next(true);
    }, 500);
  }

  openDrawer() {
    this.changeDrawerSubject.next(ChangeDrawerStatus.OPEN);
  }

  closeDrawer() {
    this.changeDrawerSubject.next(ChangeDrawerStatus.CLOSE);
  }

  toggleDrawer() {
    this.toggleSubject.next(true);
  }

  makeDrawerFull() {
    this.drawerWidth.next(DrawerWidth.FULL);
  }

  makeDrawerHalf() {
    this.drawerWidth.next(DrawerWidth.HALF);
  }

  makeDrawerNone() {
    this.drawerWidth.next(DrawerWidth.CLOSED);
  }

  connectToDrawerWidth(): Observable<DrawerWidth> {
    return this.drawerWidth.asObservable();
  }

  connectToCurrentScreen(): Observable<DrawerScreen> {
    return this.currentScreen.asObservable();
  }

  enteredGuidesView() {
    this.enteredGuidesViewSubject.next(true);
  }

  enteredGuideView() {
    this.enteredGuideViewSubject.next(true);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}

export enum ChangeDrawerStatus {
  HOLD = 'HOLD',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}

export enum DrawerWidth {
  CLOSED = 'NONE',
  HALF = 'HALF',
  FULL = 'FULL'
}

export enum DrawerScreen {
  BROWSE_GUIDES = 'BROWSE_GUIDES',
  GUIDE = 'GUIDE'
}
