import {environment} from '../../environments/environment';
import {GuideStatus} from '../models/shared/guide';

export class Endpoints {

  // Insiders

  static GetInsider(id: string): string {
    return environment.guideServiceUrl + `/get-insider?Id=${id}`;
  }

  // Guides

  static GetCompanyGuides(companyId: string, status: GuideStatus): string {
    return environment.guideServiceUrl + `/get-company-guides?CompanyId=${companyId}&Status=${status}`;
  }

  static GetGuide(id: string): string {
    return environment.guideServiceUrl + `/get-guides?Ids=${id}`;
  }

  static AddGuideView() {
    return environment.guideServiceUrl + `/add-guide-view`;
  }

  static GetCompanyGuideFeatures(companyId: string): string {
    return environment.partnerGuideServiceUrl + `/get-company-guide-features?InsiderId=${companyId}`;
  }

  // Asset

  static DeleteAsset(id, md5Hash: string): string {
    return environment.partnerGuideServiceUrl + `/delete-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  static GenerateUploadUrl(): string {
    return environment.partnerGuideServiceUrl + `/generate-upload-url`;
  }

  static GetPreview(locationId: number, displayId, menuId: string, returnLastSaved: boolean): string {
    return environment.partnerGuideServiceUrl + `/get-preview?LocationId=${locationId}&DisplayId=${displayId}&ConfigurationId=${menuId}&ReturnLastSaved=${returnLastSaved}`;
  }

  static GetAsset(id, md5Hash: string): string {
    return environment.partnerGuideServiceUrl + `/get-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

}

