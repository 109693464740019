export class ScrollUtils {

  static verticalScrollIntoView = (parent, child) => {

    const parentBounding = parent.getBoundingClientRect();
    const clientBounding = child.getBoundingClientRect();

    const parentBottom = parentBounding.bottom;
    const parentTop = parentBounding.top;
    const clientBottom = clientBounding.bottom;
    const clientTop = clientBounding.top;

    if (parentTop >= clientTop) {
      ScrollUtils.scrollToTop(parent, -(parentTop - clientTop), 300);
    } else if (clientBottom > parentBottom) {
      ScrollUtils.scrollToTop(parent, clientBottom - parentBottom, 300);
    }
  }

  static horizontalScrollIntoView = (parent, child) => {
    const parentBounding = parent.getBoundingClientRect();
    const clientBounding = child.getBoundingClientRect();

    const parentLeft = parentBounding.left;
    const parentRight = parentBounding.right;
    const clientLeft = clientBounding.left;
    const clientRight = clientBounding.right;

    if (parentLeft >= clientLeft) {
      ScrollUtils.scrollToLeft(parent, -(parentLeft - clientLeft), 500);
    } else if (clientRight > parentRight) {
      ScrollUtils.scrollToLeft(parent, clientRight - parentRight, 500);
    }
  }

  static scrollToLeft(element, to, duration) {
    const start = element.scrollLeft;
    let currentTime = 0;
    const increment = 100;

    const animateScroll = () => {
      currentTime += increment;
      element.scrollLeft = ScrollUtils.easeInOutQuad(currentTime, start, to, duration);

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  static scrollToTop(element, to, duration) {

    const start = element.scrollTop;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      element.scrollTop = ScrollUtils.easeInOutQuad(currentTime, start, to, duration);

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  // Function for smooth scroll animation with the time duration
  static easeInOutQuad(time, startPos, endPos, duration) {
    time /= duration / 2;

    if (time < 1) { return (endPos / 2) * time * time + startPos; }
    time--;
    return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
  }


}
