import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {Injectable} from '@angular/core';
import {DrawerScreen, DrawerService, DrawerWidth} from '../../../../../services/drawer.service';
import {combineLatest, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {InsiderDomainModel} from '../../../domain/insider-domain-model';

@Injectable()
export class MapFabViewModel extends BaseViewModel {

  public primaryColor$ = this.insiderDomainModel.primaryColor$;
  public secondaryColor$ = this.insiderDomainModel.secondaryColor$;
  //
  public fabMargin$ = combineLatest([
    this.drawerService.connectToCurrentScreen(),
    this.drawerService.connectToDrawerWidth(),
  ]).pipe(
    map(([screen, width]) => {
      if (screen === DrawerScreen.GUIDE && width === DrawerWidth.CLOSED) {
        return 'mobile-map-view';
      } else {
        return 'mobile-list-view';
      }
    })
  );
  public fabIcon$ = this.fabMargin$.pipe(map(it => {
    if (it === 'mobile-map-view') { return 'list'; }
    else { return 'location_on'; }
  }));
  //
  private fabSubject = new Subject<boolean>();
  private fabController = this.fabSubject
    .pipe(takeUntil(this.onDestroy))
    .subscribe(clicked => {
      if (clicked) { this.drawerService.toggleDrawer(); }
    });

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private drawerService: DrawerService
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  fabClicked() {
    this.fabSubject.next(true);
  }

}
