<div matRipple
     class="guide-feature-card-container"
     (click)="goToGuide()">
  <app-asset #asset
             class="guide-feature-card"
             [borderRadius]="'1rem'"
             [style.height]="assetHeight|async"
             [asset]="guide.getGuidePhoto()">
  </app-asset>
  <div class="guide-feature-card-info">
    <div appFont
         class="guide-feature-card-title"
         [hierarchy]="types.FontHierarchy.Primary"
         [type]="types.FontType.Bold">
      {{guide?.title}}
    </div>
    <div appFont
         class="guide-by"
         [hierarchy]="types.FontHierarchy.Primary"
         [type]="types.FontType.SemiBold">
      {{guide?.guideByStringForCards()}}
    </div>
    <div appFont
         class="short-description"
         [hierarchy]="types.FontHierarchy.Primary"
         [type]="types.FontType.Regular">
      {{guide?.description}}
    </div>
    <div appFont
         class="view-guide"
         [hierarchy]="types.FontHierarchy.Primary"
         [type]="types.FontType.SemiBold"
         (click)="goToGuide()">
      View Guide <img src="/assets/icons/dark/outline/arrow-right.svg" alt="">
    </div>
  </div>
</div>
