import {GuideStatus} from '../shared/guide';

export class GetCompanyGuidesReq {
  public companyId: string;
  public status: GuideStatus;

  constructor(companyId: string, status: GuideStatus = GuideStatus.GuideStatus_Approved) {
    this.companyId = companyId;
    this.status = status;
  }
}
