import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {FontHierarchy} from '../enum/font-hierarchy.enum';
import {FontType} from '../enum/font-type.enum';
import {BaseDirective} from '../../../models/base/base-directive';
import {FontViewModel} from './font-view-model';

@Directive({
  selector: '[appFont]',
  providers: [FontViewModel]
})
export class FontDirective extends BaseDirective
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() hierarchy: FontHierarchy = FontHierarchy.Primary;
  @Input() type: FontType = FontType.Regular;

  constructor(
    public vm: FontViewModel,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.setHierarchy(this.hierarchy);
    this.vm.setType(this.type);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.bindToFont();
  }

  private bindToFont() {
    const s = this.vm.fontClass.subscribe(fontClass => {
      if (fontClass) {
        this.renderer.addClass(this.el.nativeElement, fontClass);
      }
    });
    this.pushSub(s);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.type) {
      this.vm.setType(this.type);
    }
    if (changes?.hierarchy) {
      this.vm.setHierarchy(this.hierarchy);
    }
  }

  ngOnDestroy(): void {
    this.vm.destroy();
    this.destroy();
  }

}
