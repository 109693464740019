import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Guide} from '../../../../../models/shared/guide';
import {BaseComponent} from '../../../../../models/base/base-component';
import {TypeUtils} from '../../../utils/type-utils';
import {NavigatorService} from '../../../../../services/navigator.service';

@Component({
  selector: 'app-guides-grid',
  templateUrl: './guides-grid.component.html',
  styleUrls: ['./guides-grid.component.scss']
})
export class GuidesGridComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guides: Guide[];
  types = TypeUtils;

  constructor(
    private navigator: NavigatorService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  onGuideClick(g: Guide) {
    this.navigator.toGuide(g);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
