import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {SelectedMapCard} from './models/selected-map-card';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {KrugoMapMarker} from './interfaces/krugo-map-marker';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {MapService} from './services/map.service';
import {ScreenService} from '../../../../../services/screen.service';
import {DrawerService, DrawerWidth} from '../../../../../services/drawer.service';
import {InsiderDomainModel} from '../../../domain/insider-domain-model';
import LatLngBounds = google.maps.LatLngBounds;
import {DistinctUtils} from '../../../../../utils/distinct.utils';

@Injectable()
export class MapViewModel extends BaseViewModel implements OnDestroy {

  private markers = new BehaviorSubject<KrugoMapMarker[]>([]);
  public primaryFont$ = this.insiderDomainModel.primaryFont$;
  public primaryColor$ = this.insiderDomainModel.primaryColor$;
  private urlSrcMap = new BehaviorSubject<Map<string, string>>(new Map());
  public urlSrcMap$ = this.urlSrcMap.asObservable();
  private map = new BehaviorSubject<google.maps.Map>(null);
  public selectedMarker$ = this.mapService.selectedMarker$;
  public disableDefaultUI$ = this.screenService.connectToIsMobile();
  private boundsPadding = new BehaviorSubject<any>(JSON.parse(`{ "top": 50, "bottom": 50, "left": 50, "right": 50 }`));
  public boundsPadding$ = this.boundsPadding.asObservable();

  // Should map pan?
  public usePanning$ = combineLatest([
    this.screenService.connectToIsMobile(),
    this.drawerService.connectToDrawerWidth(),
  ]).pipe(
    map(([isMobile, drawerWidth]) => {
      if (isMobile) {
        return drawerWidth === DrawerWidth.CLOSED;
      } else {
        return true;
      }
    })
  );

  // Pan to selected map marker
  private panToSub = combineLatest([
    this.map,
    this.selectedMarker$,
    this.screenService.connectToIsMobile(),
    this.drawerService.connectToDrawerWidth(),
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([gMap, selected, isMobile, drawerWidth]) => {
      if (gMap && selected) {
        if (isMobile) {
          if (drawerWidth === DrawerWidth.CLOSED) {
            gMap.panTo(new google.maps.LatLng(selected.getLat(), selected.getLng()));
          }
        } else {
          gMap.panTo(new google.maps.LatLng(selected.getLat(), selected.getLng()));
        }
      }
    });

  // Fit Bounds
  private fitBounds = combineLatest([
    this.map.asObservable(),
    this.markers.pipe(distinctUntilChanged(DistinctUtils.krugoMapMarkers)),
  ]).pipe(takeUntil(this.onDestroy), debounceTime(50))
    .subscribe(([m, markers]) => {
      if (!!m && !!markers && markers.length > 0) {
        const bounds: LatLngBounds = new google.maps.LatLngBounds();
        for (const mm of markers) {
          bounds.extend(new google.maps.LatLng(mm.getLat(), mm.getLng()));
        }
        m.fitBounds(bounds);
      }
    });

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private mapService: MapService,
    private screenService: ScreenService,
    private drawerService: DrawerService,
  ) {
    super();
    this.init();
  }

  setSelectedMapCard(selectedMapCard: SelectedMapCard) {
    this.mapService.changeSelectedMapMarker(selectedMapCard.card.mapMarker);
  }

  addSrcUrl(url: string, m: KrugoMapMarker) {
    const myMap = this.urlSrcMap.value;
    myMap.set(m.getUniqueId(), url);
    this.urlSrcMap.next(myMap);
  }

  setMarkers(markers: KrugoMapMarker[]) {
    this.markers.next(markers);
  }

  setMap(m: google.maps.Map) {
    this.map.next(m);
  }

  setSelectedMapMarker(m: KrugoMapMarker) {
    this.mapService.changeSelectedMapMarker(m);
  }

  destroy() {
    super.destroy();
  }

  ngOnDestroy() {
    this.destroy();
  }

}
