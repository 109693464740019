import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Guide} from '../../../../../models/shared/guide';
import ResizeObserver from 'resize-observer-polyfill';
import {AssetComponent} from '../../../../../views/shared/components/asset/asset.component';
import {BehaviorSubject} from 'rxjs';
import {TypeUtils} from '../../../utils/type-utils';
import {GuideCardViewModel} from './guide-card-view-model';
import {NavigatorService} from '../../../../../services/navigator.service';

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss'],
  providers: [GuideCardViewModel],
})
export class GuideCardComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guide: Guide;
  @ViewChild('asset') asset: AssetComponent;
  types = TypeUtils;
  private assetObserver: ResizeObserver;
  public assetHeight = new BehaviorSubject<string>(null);

  constructor(
    public vm: GuideCardViewModel,
    private navigator: NavigatorService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.bindToResize();
  }

  private bindToResize() {
    this.assetObserver = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        const height = cr.height;
        const width = cr.width;
        const heightShouldBe = Math.floor(width);
        if (heightShouldBe !== height) {
          this.assetHeight.next(`${heightShouldBe}px`);
        }
      }
    });
    this.assetObserver.observe(this.asset.el.nativeElement);
  }

  public goToGuide() {
    this.navigator.toGuide(this?.guide);
  }

  ngOnDestroy(): void {
    this.assetObserver.disconnect();
    this.destroy();
  }

}
