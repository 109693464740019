import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {HydratedGuideFeature} from '../../../../../models/guides/hydrated-guide-feature';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-guides-feature',
  templateUrl: './guides-feature.component.html',
  styleUrls: ['./guides-feature.component.scss']
})
export class GuidesFeatureComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guideFeature: HydratedGuideFeature;
  @Input() hideBottomDivider: boolean = false;
  types = TypeUtils;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  getGuideFeatureTitle(): string {
    return this.guideFeature?.guideFeature.title ?? '';
  }

  getGuideFeatureDescription(): string {
    return this.guideFeature?.guideFeature.description ?? '';
  }

}
