<div class='container-404'
     fxLayout="column" fxLayoutAlign="center center" >
    <img class="word-mark"
         src="/assets/logo/krugo.png"
         alt="" />
  <div appFont
       class="text-404"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">
    404 Page Not Found
  </div>
</div>
