import * as moment from 'moment';


export class DateUtils {

  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return (DateUtils.currentTimestamp() - days * DateUtils.unixOneDay()) < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return (DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour()) < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return (DateUtils.currentTimestamp() - minutes * 60) < ts;
  }

  // Formatters

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  // Unix Helpers

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static unixOneYear(): number {
    return DateUtils.unixOneDay() * 365;
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }
}
