import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Room} from './room';
import {Coordinates} from './coordinates';
import {KrugoMapMarker} from '../../modules/krugo-guides/components/sub/map/interfaces/krugo-map-marker';
import {Asset} from '../image/dto/asset';
import {MediaType} from '../enum/dto/media-type.enum';
import {UrlUtils} from '../../utils/url-utils';

export class Accommodation implements KrugoMapMarker, Deserializable {
  public address: string;
  public bookingId: string;
  public checkInFrom: string;
  public checkInTo: string;
  public checkOutFrom: string;
  public checkOutTo: string;
  public city: string;
  public coordinates: Coordinates;
  public country: string;
  public description: string;
  public email: string;
  public facilities: string[];
  public formattedAddress: string;
  public hotelCurrency: string;
  public hotelTypeId: number;
  public importantInformation: string;
  public name: string;
  public numberOfReviews: number;
  public numberOfRooms: number;
  public paidFacilities: string[];
  public photos: Asset[];
  public payAtProperty: string;
  public payNow: string;
  public postalCode: string;
  public ranking: number;
  public reviewScore: number;
  public rooms: Room[];
  public exactStars: number;
  public stars: number;
  public url: string;

  onDeserialize() {
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.rooms = (DeserializeHelper.deserializeArray(Room, this.rooms) ?? []);
    this.facilities = Array.from(this.facilities ?? []);
    this.paidFacilities = Array.from(this.paidFacilities ?? []);
    const spoofHash = `SPOOF_HASH-${Math.random().toString(12)}`;
    (this.photos ?? []).forEach(p => {
      if (!p.fileName) {
        // default values for Booking.com assets
        p.mediaType = MediaType.JPEG;
        // set random md5 hash since asset.component requires it to be set
        p.md5Hash = spoofHash;
      }
    });
    this.photos = (DeserializeHelper.deserializeArray(Asset, this.photos) ?? []);
  }

  /** Krugo Map Marker Interface */

  getDesc(): string {
    return this.description;
  }

  getLat(): number {
    return this.coordinates.lat;
  }

  getLng(): number {
    return this.coordinates.lng;
  }

  getTitle(): string {
    return this.name;
  }

  getUniqueId(): string {
    return this.bookingId;
  }

  getCoverImage(): Asset {
    if (this.photos.length > 0) {
      return this.photos[0];
    }
    return undefined;
  }

  getImages(): Asset[] {
    return this.photos;
  }

  getUrl(): string {
    return UrlUtils.formatUrl(this.url);
  }

  hasSocials(): boolean {
    return false;
  }

  getFacebookUrl(): string {
    return null;
  }

  getInstagramUrl(): string {
    return null;
  }

  getTwitterUrl(): string {
    return null;
  }
}
