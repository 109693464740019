import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {MapViewModel} from './map-view-model';
import {ToastService} from '../../../../../services/toast-service';
import {SelectedMapCard} from './models/selected-map-card';
import {MapStyling} from './styling/map-styling';
import {KrugoMapMarker} from './interfaces/krugo-map-marker';
import {MapItemDetailsComponent} from './components/map-item-details/map-item-details.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AgmMap} from '@agm/core';
import MapTypeStyle = google.maps.MapTypeStyle;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [MapViewModel],
})
export class MapComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('map') private agmMap: AgmMap;
  @Input() mapMarkers: KrugoMapMarker[];
  @Input() selectedMarker: KrugoMapMarker;
  @Input() hideCards: boolean = false;

  public defaultZoomLevel = 12;

  constructor(
      public vm: MapViewModel,
      private toast: ToastService,
      private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.setSelectedMapMarker(this.selectedMarker);
    this.vm.setMarkers(this.mapMarkers);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMarker) {
      this.vm.setSelectedMapMarker(this.selectedMarker);
    }
    if (changes.mapMarkers) {
      this.vm.setMarkers(this.mapMarkers);
    }
  }

  mapClicked(_: (google.maps.MouseEvent | google.maps.IconMouseEvent)) {
  }

  markerClicked(marker: KrugoMapMarker, _: number) {
    this.vm.setSelectedMapMarker(marker);
  }

  guideCardClicked(item: KrugoMapMarker) {
    this.openGuideDetails(item);
  }

  private openGuideDetails(item: KrugoMapMarker): void {
    const modalRef = this.modalService.open(
      MapItemDetailsComponent,
      ModalUtils.guideItemDetailsModalOptions()
    );
    (modalRef.componentInstance as MapItemDetailsComponent).item = item;
  }

  currentVisibleCard(selectedCard: SelectedMapCard) {
    this.vm.setSelectedMapCard(selectedCard);
  }

  getMapStyling(): MapTypeStyle[] {
    return JSON.parse(MapStyling.json());
  }

  mapRdy(map: google.maps.Map) {
    this.vm.setMap(map);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
