import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ScreenService} from '../../../../../services/screen.service';
import {BaseComponent} from '../../../../../models/base/base-component';
import {PillItem} from '../../../../../models/shared/stylesheet/pill-item';

@Component({
  selector: 'app-filter-guides',
  templateUrl: './filter-guides.component.html',
  styleUrls: ['./filter-guides.component.scss']
})
export class FilterGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() pills: PillItem[] = [];
  @Output() searchText = new EventEmitter<string>(true);
  @Output() filters = new EventEmitter<string[]>(true);
  public isMobile = this.screenService.connectToIsMobile();

  constructor(
    private screenService: ScreenService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getFilters(pills: PillItem[]): string[] {
    return pills.filter(it => it.selected).map(it => it.text);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
