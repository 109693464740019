<app-loading
  [hidden]="!(loadingOpts$|async).isLoading"
  [options]="loadingOpts$|async">
</app-loading>
<app-featured-guide-banner [guide]="vm.featuredGuide$|async"></app-featured-guide-banner>
<!-- Include Guide Feature sections -->
<app-guides-feature
  *ngFor="let hgf of vm.hydratedGuideFeatures$|async; let i = index;"
  [guideFeature]="hgf"
  [hideBottomDivider]="i === (vm.hydratedGuideFeatures$|async)?.length - 1"></app-guides-feature>
<!-- Show divider if guide feature section appears-->
<hr class="divider" *ngIf="(vm.hydratedGuideFeatures$|async)?.length > 0">
<!-- Most recent guides section -->
<hr class="divider">
<app-recent-guides [guides]="vm.mostRecentGuides$|async"></app-recent-guides>
<!-- All guides section -->
<hr class="divider">
<app-guide-search [guides]="vm.allGuides$|async"></app-guide-search>
<hr class="divider">
<app-powered-by>Guides Powered by Krugo</app-powered-by>
