<div class="app-body mt-0 mb-0">
  <app-alert-banner *ngIf="!!(bannerLink$|async)"
                    [backgroundColor]="'#222222'"
                    [textColor]="'white'"
                    (click)="linkToWebsite.click();"
                    [minHeight]="(vm.isMobile$|async) ? '8rem' : '2.5rem'"
                    [startAndEndPadding]="(vm.isMobile$|async) ? '3rem' : '2.25rem'">
    <div appFont
         class="notification-banner"
         [hierarchy]="types.FontHierarchy.Primary"
         [type]="types.FontType.Bold">
      <a #linkToWebsite [href]="bannerLink$|async"
         target="_blank">View {{ (selectedGuide$|async) ? 'guide' : 'guides' }} on {{bannerTitle$|async}} website</a>
    </div>
  </app-alert-banner>
  <!-- Main content -->
  <main class="main">
    <div #routing class="routing" id="routing" [ngClass]="drawerWidthClass$|async">
      <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
    <div class="map-content" id="map">
      <app-map [mapMarkers]="(selectedGuide$|async)?.getPlanItems()"
               [hideCards]="isMapNavHidden$|async">
      </app-map>
    </div>
    <app-fab [hidden]="isMapNavHidden$|async">
      Map Navigation
    </app-fab>
  </main>
</div>
