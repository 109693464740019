import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError, map} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {Guide} from '../models/shared/guide';
import {GetCompanyGuidesReq} from '../models/guides/get-company-guides-req';
import {AddGuideViewReq, GuideClickType} from '../models/guides/add-guide-view-req';
import {AddGuideViewResponse} from '../models/guides/add-guide-view-response';
import {DateUtils} from '../utils/date-utils';
import {UUIDService} from '../services/uuid.service';
import {Insider} from '../models/shared/insider';
import {GuideFeature} from '../models/guides/guide-feature';
import {MinimalHydratedGuide} from '../models/shared/minimal-guide';

@Injectable({ providedIn: 'root' })
export class GuideApi implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private userIdService: UUIDService,
  ) {
  }

  // Variables

  public serviceName = 'Guide';

  public GetInsider(id: string): Observable<Insider> {
    const url = Endpoints.GetInsider(id);
    return this.apiClient.getObj<Insider>(Insider, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetInsider', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyGuides(req: GetCompanyGuidesReq): Observable<MinimalHydratedGuide[]> {
    const url = Endpoints.GetCompanyGuides(req.companyId, req.status);
    return this.apiClient.recursiveGet<MinimalHydratedGuide>(MinimalHydratedGuide, url, null, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyGuides', err));
        return throwError(err);
      })
    );
  }

  public GetGuide(id: string): Observable<Guide> {
    const url = Endpoints.GetGuide(id);
    return this.apiClient.getArr<Guide>(Guide, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetGuide', err));
        return throwError(err);
      }),
      map(guides => guides.first())
    );
  }

  public GetCompanyGuideFeatures(companyId: string): Observable<GuideFeature[]> {
    const url = Endpoints.GetCompanyGuideFeatures(companyId);
    return this.apiClient.getArr<GuideFeature>(GuideFeature, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyGuideFeatures', err));
        return throwError(err);
      })
    );
  }

  public AddGuideView(guideId: string, type: GuideClickType = GuideClickType.GUIDE): Observable<AddGuideViewResponse> {
    const url = Endpoints.AddGuideView();
    const req = new AddGuideViewReq(
      type,
      guideId,
      DateUtils.currentTimestamp(),
      this.userIdService.getUUID().id
    );
    return this.apiClient.postObj<AddGuideViewResponse>(
      AddGuideViewResponse,
      url,
      req,
      {UserId: this.userIdService.getUUID().id}
    ).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AddGuideView', err));
        return throwError(err);
      })
    );
  }

}
