import {Deserializable} from '../protocols/deserializable';

export class Performer implements Deserializable {
  public id: string;
  public vividId: number = -1;
  public name: string;
  public eventType: string;
  public eventTypeId: string;
  public primaryCategoryId: string;
  public secondaryCategoryId: string;
  public vividCategoryId: number = -1;
  public isParking: boolean = false;
  public priority: number = -1;
  public slug: string;
  public url: string;
  public eventCount: number = 0;
  public disposable: boolean = true;
  public lastUpdated: number;
  public searchCount: number;
  public nextEventDate: number;
  public lastEventDate: number;

  onDeserialize() {
  }
}
