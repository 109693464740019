import {FontType} from '../enum/font-type.enum';
import {Font} from '../enum/font.enum';

export class FontUtils {

  static getFontClass(type: FontType, font: Font): string {
    switch (font) {
      case Font.WorkSans: return FontUtils.getWorkSansFontClass(type);
      case Font.Rajdhani: return FontUtils.getRajdhaniFontClass(type);
    }
  }

  static getWorkSansFontClass(type: FontType): string {
    switch (type) {
      case FontType.Default:   return 'work-sans';
      case FontType.Light:     return 'work-sans-light';
      case FontType.Regular:   return 'work-sans-regular';
      case FontType.Medium:    return 'work-sans-medium';
      case FontType.SemiBold:  return 'work-sans-semi-bold';
      case FontType.Bold:      return 'work-sans-bold';
      case FontType.ExtraBold: return 'work-sans-extra-bold';
    }
  }

  static getRajdhaniFontClass(type: FontType): string {
    switch (type) {
      case FontType.Default:   return 'rajdhani';
      case FontType.Light:     return 'rajdhani-light';
      case FontType.Regular:   return 'rajdhani-regular';
      case FontType.Medium:    return 'rajdhani-medium';
      case FontType.SemiBold:  return 'rajdhani-semi-bold';
      case FontType.Bold:      return 'rajdhani-bold';
      case FontType.ExtraBold: return 'rajdhani-extra-bold';
    }
  }

}
