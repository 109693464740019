<div class="insider-container" fxLayout="column">
  <app-asset #asset
             class="insider-image"
             [borderRadius]="'50%'"
             [asset]="insider?.profilePicture">
  </app-asset>
  <div class="insider-name">{{insider?.getFullName()}}</div>
  <div class="insider-bio">{{insider?.description}}</div>

  <app-social-links
    style="margin-top: 2rem;"
    [facebookUrl]="insider?.facebookUrl"
    [instagramUrl]="insider?.instagramUrl"
    [twitterUrl]="insider?.twitterUrl"
    (socialClick)="addView($event)">
  </app-social-links>

  <div *ngIf="!!insider?.websiteUrl" class="website">
    <a class="url" target="_blank" rel="noopener noreferrer" [href]="insider?.websiteUrl"
       (click)="addView(types.GuideClickType.WEBSITE)">
      {{insider?.websiteUrl}}
    </a>
  </div>
</div>

<div class="modal-footer footer-container" fxLayout="row" fxLayoutAlign="flex-end center">
  <button matRipple
          (click)="cancel()"
          [style.background-color]="'black'"
          class="kp-button">
    <mat-icon class="close-icon" [style.color]="'white'">close</mat-icon>
  </button>
</div>
