<div class="filter-container"
     [fxLayout]="(isMobile|async) ? 'column' : 'row'">
  <app-search-bar [fxFlex]="(isMobile|async) ? '' : 30"
                  (search)="this.searchText.emit($event)">
    Search Bar
  </app-search-bar>
  <app-pill-filter [ngClass]="(isMobile|async) ? 'pill-filter-top-padding' : 'pill-filter-side-padding'"
                   [pillsIn]="this.pills"
                   (pillOut)="this.filters.emit(getFilters($event))"
                   [style.overflow-x]="'auto'"
                   [fxFlex]="'grow'">
    Search Filters
  </app-pill-filter>
</div>
