import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Guide} from '../../../../../../../models/shared/guide';
import {BaseComponent} from '../../../../../../../models/base/base-component';
import {TypeUtils} from '../../../../../utils/type-utils';
import {GuideSectionItemViewModel} from './guide-section-item-view-model';
import {KrugoMapMarker} from '../../../map/interfaces/krugo-map-marker';
import {GuideClickType} from '../../../../../../../models/guides/add-guide-view-req';
import {MapService} from "../../../map/services/map.service";

@Component({
  selector: 'app-guide-section-item',
  templateUrl: './guide-section-item.component.html',
  styleUrls: ['./guide-section-item.component.scss'],
  providers: [GuideSectionItemViewModel],
})
export class GuideSectionItemComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: Guide;
  @Input() itemId: string;
  @Input() selectedMapMarker: KrugoMapMarker;
  types = TypeUtils;
  @ViewChild('url') url: ElementRef;

  constructor(
    public vm: GuideSectionItemViewModel,
    private mapService: MapService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setDataInViewModel();
  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataInViewModel();
  }

  onGuideItemClick(mouseEvent: MouseEvent) {
    const urlBox = this.url?.nativeElement?.getBoundingClientRect();
    if (urlBox) {
      const isInsideUrlBox = this.clickInsideBox(urlBox, mouseEvent);
      if (!isInsideUrlBox) {
        this.vm.clickToggled();
      }
    } else {
      this.vm.clickToggled();
    }
  }

  private clickInsideBox(rec: DOMRect, mouseEvent: MouseEvent): boolean {
    const mouseX = mouseEvent.x;
    const mouseY = mouseEvent.y;
    const topLeftX = rec.x;
    const topLeftY = rec.y;
    const bottomRightX = rec.right;
    const bottomRightY = rec.bottom;

    let insideXBounds: boolean = false;
    if (topLeftX <= mouseX && mouseX <= bottomRightX) {
      insideXBounds = true;
    }
    let insideYBounds: boolean = false;
    if (topLeftY <= mouseY && mouseY <= bottomRightY) {
      insideYBounds = true;
    }
    return insideXBounds && insideYBounds;
  }

  selectOnMap() {
    this.vm.hoverToggled();
  }

  private setDataInViewModel() {
    this.vm.setGuide(this.guide);
    this.vm.setItemId(this.itemId);
    this.vm.setSelectedMapMarker(this.selectedMapMarker);
  }

  ngOnDestroy() {
    this.mapService.changeSelectedMapMarker(null);
    this.vm.destroy();
    this.destroy();
  }

  addView(type: GuideClickType) {
    this.vm.addView(type);
  }

}
