import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {SessionService} from '../../../../../services/session-service';
import {Router, RouterOutlet} from '@angular/router';
import {BaseComponent} from '../../../../../models/base/base-component';
import {DrawerScreen, DrawerService, DrawerWidth} from '../../../../../services/drawer.service';
import {map, takeUntil} from 'rxjs/operators';
import {PlatformLocation} from '@angular/common';
import {ScreenService} from '../../../../../services/screen.service';
import {HomeViewModel} from './home-view-model';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {combineLatest} from 'rxjs';
import {slideInAnimation} from '../../../animations/animations';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-default-layout',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomeViewModel],
  animations: [slideInAnimation]
})
export class HomeComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('routing') routing: ElementRef;

  public selectedGuide$ = this.vm.selectedGuide$;
  public bannerLink$ = this.vm.insider$.pipe(map(insider => {
    if (!!insider && !!insider?.websiteUrl && !this.inIframe()) {
      if (!!insider.embeddedWebsiteUrl) {
        return insider.embeddedWebsiteUrl;
      } else {
        return insider.websiteUrl;
      }
    }
    return '';
  }));
  public bannerTitle$ = this.vm.insider$.pipe(map(insider => {
    if (!!insider && !!insider?.companyName && !this.inIframe()) {
      return insider.companyName;
    }
    return '';
  }));

  public drawerWidthClass$ = this.drawerService.connectToDrawerWidth()
    .pipe(
      takeUntil(this.onDestroy),
      map(width => {
        switch (width) {
          case DrawerWidth.CLOSED:
            return 'closed';
          case DrawerWidth.HALF:
            return 'half';
          case DrawerWidth.FULL:
            return 'full';
        }
      })
    );

  public noVerticalScroll$ = this.screenService.connectToIsMobile().pipe(
    map(isMobile => {
      return isMobile && this.inIframe();
    })
  );

  public isMapNavHidden$ = combineLatest([
    this.screenService.connectToIsMobile(),
    this.drawerService.connectToCurrentScreen(),
  ]).pipe(
    map(([isMobile, drawerScreen]) => {
      return !(isMobile && (drawerScreen === DrawerScreen.GUIDE));
    })
  );

  types = TypeUtils;

  constructor(
    public vm: HomeViewModel,
    private dm: GuidesDomainModel,
    private sessionService: SessionService,
    private router: Router,
    private location: PlatformLocation,
    private drawerService: DrawerService,
    private screenService: ScreenService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.onResize();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenService.setIsMobile((this.el.nativeElement as HTMLElement).getBoundingClientRect().width < ScreenService.MOBILE_WIDTH);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  ngOnDestroy(): void {
    this.vm.destroy();
    this.destroy();
  }

  inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

}
