import {Cachable} from '../protocols/cachable';
import {Deserializable} from '../protocols/deserializable';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {DateUtils} from '../../utils/date-utils';

export class UUID implements Cachable, Deserializable {

  public id: string;
  // From cacheable
  cachedTime: number;

  constructor() {
  }

  static buildCacheKey() {
    return 'uuid';
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneYear();
  }

  cacheKey(): string {
    return 'uuid';
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  // From deserializable
  onDeserialize() {
  }
}
