import {Asset} from '../models/image/dto/asset';
import {Guide} from '../models/shared/guide';
import {KrugoMapMarker} from '../modules/krugo-guides/components/sub/map/interfaces/krugo-map-marker';
import {MapCardComponent} from '../modules/krugo-guides/components/sub/map/components/map-card/map-card.component';
import {HydratedGuideFeature} from '../models/guides/hydrated-guide-feature';

export class DistinctUtils {
  public static distinctKrugoMapMarker = (prev: KrugoMapMarker, curr: KrugoMapMarker) => prev?.getUniqueId() === curr?.getUniqueId();
  public static krugoMapMarkers = (prev: KrugoMapMarker[], curr: KrugoMapMarker[]) => {
    const prevId = prev?.map(mm => `${mm.getUniqueId()}`).sort().join(',');
    const currId = curr?.map(mm => `${mm.getUniqueId()}`).sort().join(',');
    return prevId === currId;
  }
  public static distinctAsset = (prev: Asset, curr: Asset) => prev?.md5Hash === curr?.md5Hash;
  public static distinctGuide = (prev: Guide, curr: Guide) => prev?.id === curr?.id && prev?.cachedTime === curr?.cachedTime;
  public static distinctHydratedGuideFeatures = (prev: HydratedGuideFeature[], curr: HydratedGuideFeature[]) => {
    const prevId = prev.map(hgf => `${hgf.guideFeature.id}-${hgf.guides.map(g => g.id).sort().join(',')}`);
    const currId = curr.map(hgf => `${hgf.guideFeature.id}-${hgf.guides.map(g => g.id).sort().join(',')}`);
    return prevId === currId;
  }
  public static distinctGuides = (prev: Guide[], curr: Guide[]) => {
    const prevId = prev?.map(g => `${g.id}-${g.cachedTime}`).sort().join(',');
    const currId = curr?.map(g => `${g.id}-${g.cachedTime}`).sort().join(',');
    return prevId === currId;
  }
  public static distinctMapCardComponentWithIndex =
    ([prev, pIndex]: [MapCardComponent, number], [curr, cIndex]: [MapCardComponent, number]) => {
      return DistinctUtils.distinctMapCardComponent(prev, curr) && pIndex === cIndex;
    }
  public static distinctMapCardComponent = (prev: MapCardComponent, curr: MapCardComponent) => {
    return prev?.mapMarker?.getUniqueId() === curr?.mapMarker?.getUniqueId();
  }
}

