import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Guide} from '../../../../../models/shared/guide';
import {BaseComponent} from '../../../../../models/base/base-component';
import {KrugoMapMarker} from '../map/interfaces/krugo-map-marker';
import {ScreenService} from '../../../../../services/screen.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guide: Guide;
  @Input() selectedMapMarker: KrugoMapMarker;

  constructor(
    private screenService: ScreenService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
    setTimeout(() => {
      this.screenService.publishScrollPosition.next(0);
      this.screenService.publishIFrameHeight.next(true);
    }, 200);
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
