import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {BehaviorSubject} from 'rxjs';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../../models/enum/shared/loading-spinner-size.enum';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class BrowseGuidesViewModel extends BaseViewModel {

  allGuides$ = this.dm.allPublicGuides$;
  featuredGuide$ = this.dm.featuredGuide$;
  mostRecentGuides$ = this.dm.mostRecentGuides$;
  hydratedGuideFeatures$ = this.dm.hydratedGuideFeatures$;

  // Loading
  private loadingOpts = new BehaviorSubject<LoadingOptions>(this.initLoadingOpts());
  public loadingOpts$ = this.loadingOpts.asObservable();

  constructor(
    private dm: GuidesDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.listenForLoadingGuides();
  }

  private listenForLoadingGuides() {
    this.dm.allPublicGuides$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((guides) => {
        if (guides === null || guides === undefined) {
          const opt = this.loadingOpts.value;
          opt.isLoading = true;
          this.loadingOpts.next(opt);
        } else {
          const opt = this.loadingOpts.value;
          opt.isLoading = false;
          this.loadingOpts.next(opt);
        }
      });
  }

  private initLoadingOpts(): LoadingOptions {
    const x = LoadingOptions.default(false, true);
    x.isLoading = false;
    x.spinnerColor = '#000000';
    x.spinnerSize = LoadingSpinnerSize.Medium;
    x.color = '#000000';
    x.loadingText = 'Loading Guides';
    return x;
  }

}
