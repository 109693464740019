import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {KrugoMapMarker} from '../../interfaces/krugo-map-marker';
import {BaseComponent} from '../../../../../../../models/base/base-component';
import {MapItemDetailsViewModel} from './map-item-details-view-model';
import {TypeUtils} from '../../../../../utils/type-utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GuideClickType} from '../../../../../../../models/guides/add-guide-view-req';
import {ScrollUtils} from '../../../../../utils/scroll-utils';
import {ScreenService} from '../../../../../../../services/screen.service';

@Component({
  selector: 'app-map-item-details',
  templateUrl: './map-item-details.component.html',
  styleUrls: ['./map-item-details.component.scss'],
  providers: [MapItemDetailsViewModel],
})
export class MapItemDetailsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('textContainer') textContainer: ElementRef;
  @Input() item: KrugoMapMarker;
  public bannerOverlay = this.vm.bannerOverlay.asObservable();
  types = TypeUtils;

  constructor(
    public vm: MapItemDetailsViewModel,
    private activeModal: NgbActiveModal,
    private screenService: ScreenService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.textContainer.nativeElement.scrollTo(0, 0);
      this.screenService.publishScrollPosition.next(this.el.nativeElement?.getBoundingClientRect()?.top ?? 0);
    }, 100);
    this.setupBindings();
  }

  setupBindings() {
    const s = this.vm.closeModal.subscribe(close => {
      if (close) {
        this.activeModal.close();
      }
    });
    this.pushSub(s);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  viewOnMap() {
    this.vm.viewOnMap(this.item);
  }

  close() {
    this.activeModal.close(null);
  }

  addView(type: GuideClickType) {
    this.vm.addView(type);
  }

}
