import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Coordinates} from './coordinates';
import {KrugoMapMarker} from '../../modules/krugo-guides/components/sub/map/interfaces/krugo-map-marker';
import {Asset} from '../image/dto/asset';
import {UrlUtils} from '../../utils/url-utils';

export class CustomPlace implements KrugoMapMarker, Deserializable {

  public guideId: string;
  public id: string;
  public name: string;
  public address: string;
  public city: string;
  public stateCode: string;
  public countryCode: string;
  public postalCode: string;
  public coordinates: Coordinates;
  public url: string;
  public instagramUrl: string = '';
  public facebookUrl: string = '';
  public twitterUrl: string = '';
  public categoryIds: string[];
  // Not from api
  public desc: string;
  public images: Asset[] = [];

  onDeserialize() {
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.images = [];
    this.categoryIds = Array.from(this.categoryIds ?? []);
  }

  initDesc(d: string) {
    this.desc = d;
  }

  /** Krugo Map Marker Interface */

  getDesc(): string {
    return this.desc;
  }

  getLat(): number {
    return this.coordinates.lat;
  }

  getLng(): number {
    return this.coordinates.lng;
  }

  getTitle(): string {
    return this.name;
  }

  getUniqueId(): string {
    return this.id;
  }

  getCoverImage(): Asset {
    if (this.images.length > 0) {
      return this.images[0];
    }
    return undefined;
  }

  getImages(): Asset[] {
    return this.images;
  }

  getUrl(): string {
    return UrlUtils.formatUrl(this.url);
  }

  hasSocials(): boolean {
    return !!this.getFacebookUrl() || !!this.getInstagramUrl() || !!this.getTwitterUrl();
  }

  getFacebookUrl(): string {
    return this.facebookUrl;
  }

  getInstagramUrl(): string {
    return this.instagramUrl;
  }

  getTwitterUrl(): string {
    return this.twitterUrl;
  }
}
