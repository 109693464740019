<div *ngIf="hasSocials()" class="place-socials" fxLayout="row" fxLayoutAlign="start">
  <div *ngIf="!!facebookUrl"
       class="social-button">
    <a target="_blank" rel="noopener noreferrer" [href]="getFormattedFacebookUrl()"
       (click)="handleLinkClick(types.GuideClickType.FACEBOOK)">
      <img class="social-logo" [src]="'assets/logo/dark/facebook-logo.svg'" alt=""
           [className]="smallIcons ? 'small-icons' : 'social-logo'">
    </a>
  </div>
  <div *ngIf="!!instagramUrl"
       class="social-button">
    <a target="_blank" rel="noopener noreferrer" [href]="getFormattedInstagramUrl()"
       (click)="handleLinkClick(types.GuideClickType.INSTAGRAM)">
      <img class="social-logo" [src]="'assets/logo/dark/instagram-logo.svg'" alt=""
           [className]="smallIcons ? 'small-icons' : 'social-logo'">
    </a>
  </div>
  <div *ngIf="!!twitterUrl"
       class="social-button">
    <a target="_blank" rel="noopener noreferrer" [href]="getFormattedTwitterUrl()"
       (click)="handleLinkClick(types.GuideClickType.TWITTER)">
      <img class="social-logo" [src]="'assets/logo/dark/twitter-logo.svg'" alt=""
           [className]="smallIcons ? 'small-icons' : 'social-logo'">
    </a>
  </div>
</div>
