<div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">

  <div #container class="fill">
    <swiper #swiper
            class="swiper-container"
            [disabled]="(assets?.length <= 1)"
            [config]="config"
            (indexChange)="onIndexChange($event)">

      <img *ngIf="!assets || assets?.length < 1"
           class="place-holder"
           src="assets/placeholder/no-image.svg" alt="">

      <app-asset #myAsset
                 *ngFor="let asset of assets"
                 class="swiper-slide swiper-asset"
                 [asset]="asset"
                 [scaleFit]="scaleFit"
                 [style.width.px]="assets.length > 1 ? (myAsset.el.nativeElement.clientHeight)*(myAsset.ratio|async) : ''"
                 [showControls]="true"
                 [size]="asset.isVideo() ? AssetSize.Large : AssetSize.Large">
      </app-asset>
    </swiper>
  </div>

  <div *ngIf="overlay.enabled"
       class="not-clickable"
       [style.height]="swiperHeight|async"
       [style.opacity]="overlay.opacity"
       [style.background]="overlay.color">
  </div>

  <div class="not-clickable"
       [style.height]="this.swiperHeight|async">
    <ng-content></ng-content>
  </div>

</div>
