<div class="guide-section-container"
     id="guide-section-container">
  <div appFont
       class="guide-section-title"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.SemiBold">
    {{section?.title}}
  </div>
  <hr class="divider">
  <app-guide-section-item *ngFor="let id of section.sortedIds; let i = index"
                          [itemId]="id"
                          [guide]="guide"
                          [selectedMapMarker]="selectedMapMarker"
                          class="guide-section-item">
    Guide Section Item
  </app-guide-section-item>
  <div></div>
</div>
