import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Insider} from './insider';
import {Asset} from '../image/dto/asset';
import {Performer} from './performer';
import {GuideSection} from './guide-section';
import {Accommodation} from './accommodation';
import {Cachable} from '../protocols/cachable';
import {DateUtils} from '../../utils/date-utils';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {SortUtils} from '../../modules/krugo-guides/utils/sort-utils';
import {KrugoMapMarker} from '../../modules/krugo-guides/components/sub/map/interfaces/krugo-map-marker';
import {CustomPlace} from './custom-place';
import {Place} from './place';
import {environment} from '../../../environments/environment';
import {Pagable} from '../protocols/pagable';

export class Guide implements Deserializable, Cachable, Pagable {

  public cityId: string;
  public companyId: string;
  public company: Insider;
  public contentDescription: Map<string, string>;
  public customPlaces: CustomPlace[];
  public description: string;
  public favoriteCount: number;
  public featuredGuide: boolean;
  public id: string;
  public imageMap: Map<string, string[]>;
  public imageOrderMap: Map<string, Map<string, number>>;
  public images: Asset[];
  public insider: Insider;
  public insiderId: string;
  public lastUpdated: number;
  public performer: Performer;
  public performerId: string;
  public places: Place[];
  public placeIds: string[];
  public sections: GuideSection[];
  public shortDescription: string;
  public showCompanyInsider: boolean;
  public isPrivate: boolean;
  public status: GuideStatus;
  public statusNote: string;
  public stayIds: string[];
  public stays: Accommodation[];
  public tags: string[];
  public title: string;
  public viewCount: number;
  // Cache
  cachedTime: number;

  protected loadMinimalImages: boolean;

  static buildArrayCacheKey(insiderId: string): string {
    return `Guides-${insiderId}`;
  }

  static buildCacheKey(id: string): string {
    return `Guide-${id}`;
  }

  constructor() {
  }

  onDeserialize() {
    this.company = DeserializeHelper.deserializeToInstance(Insider, this.company);
    this.contentDescription = (DeserializeHelper.deserializeGenericMap(this.contentDescription) ?? new Map());
    this.customPlaces = (DeserializeHelper.deserializeArray(CustomPlace, this.customPlaces) ?? []);
    this.imageMap = (DeserializeHelper.deserializeGenericArrayMap(this.imageMap) ?? new Map());
    this.imageOrderMap = (DeserializeHelper.deserializeNestedGenericMap(this.imageOrderMap) ?? new Map());
    this.images = (DeserializeHelper.deserializeArray(Asset, this.images) ?? []);
    this.insider = DeserializeHelper.deserializeToInstance(Insider, this.insider);
    this.performer = DeserializeHelper.deserializeToInstance(Performer, this.performer);
    this.placeIds = Array.from(this.placeIds ?? []);
    this.places = (DeserializeHelper.deserializeArray(Place, this.places) ?? []);
    this.sections = (DeserializeHelper.deserializeArray(GuideSection, this.sections) ?? []);
    this.sections.sort(SortUtils.sortGuideSections);
    this.stays = (DeserializeHelper.deserializeArray(Accommodation, this.stays) ?? []);
    this.stayIds = Array.from(this.stayIds ?? []);
    this.tags = Array.from(this.tags ?? []);
    // must wait for full object to be deserialized before referencing its properties
    if (this.loadMinimalImages === undefined) {
      this.loadMinimalImages = false;
    }
    this.sortImages();
    if (this.loadMinimalImages) {
      const guidePhoto = this.getGuidePhoto();
      this.images =  guidePhoto ? [guidePhoto] : [];
    }
    this.setDescsOnCustomPlaces();
    this.setImagesOnCustomPlaces();
    this.setDescsOnPlaces();
    this.setImagesOnPlaces();
  }

  private setDescsOnCustomPlaces() {
    this.customPlaces?.forEach(place => place.initDesc(this.contentDescription?.get(place.id)));
  }

  private setImagesOnCustomPlaces() {
    this.customPlaces?.forEach(place => {
      const hashes = this.imageMap?.get(place.id);
      hashes?.forEach(hash => {
        const img = this.images?.find(image => image.md5Hash === hash);
        if (img) {
          place.images.push(img);
        }
      });
      // sort place images
      const imageOrder = this.imageOrderMap?.get(place.id);
      place.images = SortUtils.sortAssetsByMap(place.images, imageOrder);
    });
  }

  private setDescsOnPlaces() {
    this.places?.forEach(place => place.initDesc(this.contentDescription?.get(place.placeId)));
  }

  private setImagesOnPlaces() {
    this.places?.forEach(place => {
      const hashes = this.imageMap?.get(place.placeId);
      hashes?.forEach(hash => {
        const img = this.images?.find(image => image.md5Hash === hash);
        if (img) {
          place.images.push(img);
        }
      });
      // sort place images
      const imageOrder = this.imageOrderMap?.get(place.placeId);
      place.images = SortUtils.sortAssetsByMap(place.images, imageOrder);
    });
  }

  public hasCompany(): boolean {
    return !!this.company;
  }

  public hasInsider(): boolean {
    return !!this.insider;
  }

  public getCompanyOrInsider(): Insider {
    return !!this.company ? this.company : this.insider;
  }

  public guideByStringForCards(): string {
    if (!!this.company) {
      if (this.showCompanyInsider) {
        return `${this.insider.firstName} ${this.insider.lastName}`;
      } else {
        return `${this.company.companyName}`;
      }
    } else {
      return `${this.insider.getFullName()}`;
    }
  }

  public getGuidePhoto(): Asset {
    const guideImageSortOrder = this.imageOrderMap?.get(this.id);
    if (!!guideImageSortOrder && guideImageSortOrder.size > 0) {
        return this.images[0];
    } else {
      const photoHash = this.imageMap?.get(this.id)?.first();
      if (!!photoHash) {
          return this.images?.find(it => it.md5Hash === photoHash);
      }
    }
    return this.images[0];
  }

  public sortImages() {
    const guideImageSortOrder = this.imageOrderMap?.get(this.id);
    this.images = SortUtils.sortAssetsByMap(this.images, guideImageSortOrder);
  }

  // Caching

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(guideId: string): string {
    return Guide.buildCacheKey(this.id);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Session;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  findPlanItem(id: string): KrugoMapMarker {
    return this.getPlanItems().find(it => it.getUniqueId() === id);
  }

  getPlanItems(): KrugoMapMarker[] {
    return [
      ...this.customPlaces?.map(it => [this, it as KrugoMapMarker] as [Guide, KrugoMapMarker]),
      ...this.places?.map(it => [this, it as KrugoMapMarker] as [Guide, KrugoMapMarker]),
      ...this.stays?.map(it => [this, it as KrugoMapMarker] as [Guide, KrugoMapMarker])
    ].filter(item => this.findPlanItemIndex(item[1].getUniqueId()) > -1)
      .sort(SortUtils.sortGuideItems)
      .map(it => it[1]);
  }

  findPlanItemIndex(id: string): number {
    let offset = 0;
    const sectionIndex = this.sections?.findIndex(it => it.sortedIds?.contains(id));
    if (sectionIndex !== undefined && sectionIndex > -1) {
      for (let i = 0; i < sectionIndex; i++) {
        if (this.sections) {
          offset += this.sections[i].sortedIds.length;
        }
      }
      if (this.sections) {
        const indexInSection = this.sections[sectionIndex]?.sortedIds?.findIndex(it => it === id);
        return offset + indexInSection;
      }
    }
    return -1;
  }

  getLink(): string {
    const baseUrl = environment.production ? 'embed.krugopartners.com' : 'staging.embed.krugopartners.com';
    return `https://${baseUrl}/#/guides/${this.companyId ?? this.insiderId}/guide/${this.id}`;
  }

  getStartKey(): string {
    return this.id;
  }

}

export enum GuideStatus {
  GuideStatus_Draft = 'GuideStatus_Draft',
  GuideStatus_Pending = 'GuideStatus_Pending',
  GuideStatus_Approved = 'GuideStatus_Approved',
  GuideStatus_Declined = 'GuideStatus_Declined',
  GuideStatus_Archived = 'GuideStatus_Archived'
}
