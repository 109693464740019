<div matRipple
     class="item-container"
     (mouseover)="selectOnMap()"
     (click)="onGuideItemClick($event)">
  <app-asset class="asset"
             fxFlex="none"
             [asset]="(vm.item$|async)?.getCoverImage()"
             [showControls]="false"
             [style.object-fit]="'cover'"
             [borderRadius]="'10px'">
    Asset
  </app-asset>
  <div class="text-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
    <div fxLayout="row" fxLayoutAlign=" center">
      <div appFont
           fxFlex
           class="title"
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Bold">
        {{vm.itemIndex$|async}}. {{(vm.item$|async)?.getTitle()}}
      </div>
      <div [hidden]="!(vm.isItemSelected$|async)"
           [style.background-color]="vm.primaryColor$|async"
           fxFlex="none"
           class="viewing"
           appFont
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Regular">
        Viewing
      </div>
    </div>
    <div appFont
         class="desc"
         [hierarchy]="types.FontHierarchy.Secondary"
         [type]="types.FontType.Regular">
      {{(vm.item$|async)?.getDesc()}}
    </div>
    <br *ngIf="!!(vm.item$|async)?.getUrl() || !!(vm.item$|async)?.hasSocials()">

    <app-social-links
      [facebookUrl]="(vm.item$|async)?.facebookUrl"
      [instagramUrl]="(vm.item$|async)?.instagramUrl"
      [twitterUrl]="(vm.item$|async)?.twitterUrl"
      [smallIcons]="true"
      (socialClick)="addView($event)">
    </app-social-links>

    <a *ngIf="!!(vm.item$|async)?.getUrl()"
       #url
       class="url"
       target="_blank" rel="noopener noreferrer"
       [href]="(vm.item$|async)?.getUrl()">
      View Website
    </a>
  </div>
</div>
<hr class="divider">
