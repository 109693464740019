<div class="recent-guides-container">
  <div appFont
       class="most-recent"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">
    Recent Guides
  </div>
  <div>
    <app-guide-card *ngFor="let guide of guides"
                    [guide]="guide">
    </app-guide-card>
  </div>
</div>
