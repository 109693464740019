import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MapFabViewModel} from './map-fab-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';

@Component({
  selector: 'app-fab',
  templateUrl: './map-fab.component.html',
  styleUrls: ['./map-fab.component.scss'],
  providers: [MapFabViewModel],
})
export class MapFabComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public vm: MapFabViewModel
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
  }

  fabClicked() {
    this.vm.fabClicked();
  }

  ngOnDestroy() {
    this.vm.destroy();
    this.destroy();
  }

}
