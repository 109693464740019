<div class="search-guides-container">
  <div appFont
       class="more-guides"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">
    More Guides
  </div>
  <app-filter-guides [pills]="vm.searchPills|async"
                     (searchText)="vm.searchString.next($event)"
                     (filters)="vm.filters.next($event)">
    Pill Filters
  </app-filter-guides>
  <app-guides-paginated [paginatedGuides]="vm.paginatedGuides|async">
    Paginated Guides
  </app-guides-paginated>
</div>
