import {Guide} from '../../../models/shared/guide';
import {GuideSection} from '../../../models/shared/guide-section';
import {KrugoMapMarker} from '../components/sub/map/interfaces/krugo-map-marker';
import {Asset} from '../../../models/image/dto/asset';

export class SortUtils {

  static sortGuideItems = (a: [Guide, KrugoMapMarker], b: [Guide, KrugoMapMarker]): number => {
    return a[0].findPlanItemIndex(a[1].getUniqueId()) - b[0].findPlanItemIndex(b[1].getUniqueId());
  }
  static sortGuidesByMostRecent = (a: Guide, b: Guide): number => {
    return b.lastUpdated - a.lastUpdated;
  }
  static sortGuideSections = (a: GuideSection, b: GuideSection): number => {
    return a.sortOrder - b.sortOrder;
  }
  static sortKeyNumberPairs = (a: [key: string, value: number], b: [key: string, value: number]): number => {
    return a[1] - b[1];
  }
  static sortAssetsByMap(assets: Asset[], orderMap: Map<string, number>): Asset[] {
    if (!!orderMap) {
      // Generate tmp with sorted orderMap
      const tmp: [string, number][] = [];
      for (const [key, value] of orderMap.entries()) {
        tmp.push([key, value]);
      }
      tmp.sort(SortUtils.sortKeyNumberPairs);
      // Iterate over sorted orderMap and apply assets
      const sortedAssets = [];
      tmp.forEach(([key, _]) => {
        const nextAsset = assets.find(a => a.md5Hash === key);
        if (!!nextAsset) {
          sortedAssets.push(nextAsset);
        }
      });
      // Iterate over assets and add any that have been missed
      assets.forEach(a => {
        const sortedAsset = sortedAssets.find(sa => sa.md5Hash === a.md5Hash);
        if (!sortedAsset) {
          sortedAssets.push(a);
        }
      });
      return sortedAssets;
    } else {
      return assets;
    }
  }
}
