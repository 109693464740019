import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TypeUtils} from '../../../utils/type-utils';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Guide} from '../../../../../models/shared/guide';
import {GuideSearchViewModel} from './guide-search-view-model';

@Component({
  selector: 'app-guide-search',
  templateUrl: './guide-search.component.html',
  styleUrls: ['./guide-search.component.scss'],
  providers: [GuideSearchViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideSearchComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guides: Guide[] = [];
  types = TypeUtils;

  constructor(
    public vm: GuideSearchViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.guides.next(this.guides);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.guides) {
      this.vm.guides.next(this.guides);
    }
  }

  ngOnDestroy(): void {
    this.vm.destroy();
    this.destroy();
  }

}
