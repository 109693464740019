export class ModalUtils {

  public static confirmationModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  public static defaultModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  public static guideItemDetailsModalOptions(): any {
    return ModalUtils.medium();
  }

  public static insiderModalOptions(): any {
    return ModalUtils.medium();
  }

  public static shareToSocialModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  private static medium(): any {
    return {
      size: 'md',
      centered: true,
      scrollable: true,
    };
  }

  private static mediumStatic(): any {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
    };
  }

  private static largeStatic(): any {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    };
  }


}
