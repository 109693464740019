<div class="guide-feature-container">
  <div appFont
       class="guide-feature-title"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">
    {{ getGuideFeatureTitle() }}
  </div>
  <div appFont
       class="guide-feature-description"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Medium">
    {{ getGuideFeatureDescription() }}
  </div>
  <div class="guide-feature-card-container">
    <app-guide-feature-card *ngFor="let guide of guideFeature.guides"
                            [guide]="guide">
    </app-guide-feature-card>
  </div>

  <div *ngIf="!hideBottomDivider" class="divider"></div>
</div>

