import {MapCardComponent} from '../components/map-card/map-card.component';


export class SelectedMapCard {
  public card: MapCardComponent;
  public index: number;

  constructor(c: MapCardComponent, i: number) {
    this.card = c;
    this.index = i;
  }
}
