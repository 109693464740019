import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {PillItem} from '../../../../../../models/shared/stylesheet/pill-item';

@Component({
  selector: 'app-pill-filter',
  templateUrl: './pill-filter.component.html',
  styleUrls: ['./pill-filter.component.scss']
})
export class PillFilterComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() pillsIn: PillItem[] = [];
  @Output() pillOut = new EventEmitter(true);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  changed() {
    this.pillOut.emit(this.pillsIn);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
