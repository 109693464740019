import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {MediaType} from '../../enum/dto/media-type.enum';
import {AssetSize} from '../../enum/dto/asset-size.enum';
import {Cachable} from '../../protocols/cachable';
import {DateUtils} from '../../../utils/date-utils';
import {MediaUtils} from '../../../utils/media-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import {AssetUrl} from './asset-url';

export class Asset implements Deserializable, Cachable {
  public id: string;
  public md5Hash: string;
  public urls: AssetUrl[];
  public timestamp: number;
  public fileName: string;
  public mediaType: MediaType;
  public cachedTime: number;
  // Override Deserialize Sizes
  public sizeOverrides: AssetSize[];

  static buildCacheKey(id: string, size: AssetSize, hash: string): string {
    return `Image-${id}-${size}-${hash}`;
  }

  public isEmpty() {
    return this.id === '' || this.md5Hash === '';
  }

  public onDeserialize() {
    if (this.sizeOverrides && this.sizeOverrides.length > 0) {
      const filteredUrls = this.urls?.filter(u => {
        return (this.sizeOverrides.filter(filter => filter === u.size).length > 0);
      });
      this.urls = DeserializeHelper.deserializeArray(AssetUrl, filteredUrls);
    } else {
      // Default Cache Sizes
      const filteredUrls = this.urls?.filter(u => {
        if (MediaUtils.isVideo(this.mediaType)) {
          return u.size === AssetSize.Large;
        } else {
          return u.size === AssetSize.Medium || u.size === AssetSize.Large;
        }
      });
      this.urls = DeserializeHelper.deserializeArray(AssetUrl, filteredUrls);
    }
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/)?.length > 0;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/)?.length > 0;
  }

  public getAssetUrl(size: AssetSize): AssetUrl {
    return this.urls?.find(u => u.size === size);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(size: AssetSize): string {
    return Asset.buildCacheKey(this.id, size, this.md5Hash);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
