import {Injectable} from '@angular/core';
import {BaseDomainModel} from '../../../models/base/base-domain-model';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {Guide} from '../../../models/shared/guide';
import {distinctUntilChanged, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {SortUtils} from '../utils/sort-utils';
import {ActivatedRoute} from '@angular/router';
import {CacheService} from '../../../services/cache-service';
import {DistinctUtils} from '../../../utils/distinct.utils';
import {GuideApi} from '../../../api/guide-api';
import {CustomError} from '../../../models/shared/custom-error';
import {ToastService} from '../../../services/toast-service';
import {GetCompanyGuidesReq} from '../../../models/guides/get-company-guides-req';
import {GuideFeature} from '../../../models/guides/guide-feature';
import {HydratedGuideFeature} from '../../../models/guides/hydrated-guide-feature';

@Injectable({providedIn: 'root'})
export class GuidesDomainModel extends BaseDomainModel {

  // Query Data
  private insiderId = new BehaviorSubject<string>(null);
  public guideId = new BehaviorSubject<string>(null);

  // Guides
  private allGuides = new BehaviorSubject<Guide[]>(null);
  private allGuideFeatures = new BehaviorSubject<GuideFeature[]>(null);

  // Observable
  public insiderId$ = this.insiderId.asObservable();
  public guideId$ = this.guideId.asObservable();
  public allPublicGuides$ = this.allGuides.pipe(map(guides => {
    return guides?.filter(g => !g.isPrivate).sort(SortUtils.sortGuidesByMostRecent);
  }));
  public allGuides$ = this.allGuides.pipe(map(guides => {
    return guides?.sort(SortUtils.sortGuidesByMostRecent);
  }));
  public mostRecentGuides$ = this.allPublicGuides$.pipe(map(guides => guides?.slice(0, 4)));

  public selectedGuideLoading$ = new BehaviorSubject<boolean>(false);
  public selectedGuide$ = this.guideId$.notNull().pipe(
    tap((guideId) => this.selectedGuideLoading$.next(!!guideId)),
    switchMap(guideId => this.guideApi.GetGuide(guideId)),
    tap(guide => {
      this.selectedGuideLoading$.next(false);
      if (!!guide) {
        this.cache.cacheObject<Guide>(Guide.buildCacheKey(guide.id), guide);
      }
    }),
    distinctUntilChanged(DistinctUtils.distinctGuide),
    shareReplay(1)
  );

  // Featured Guide
  private featuredGuide = this.allPublicGuides$
    .pipe(takeUntil(this.onDestroy),
      map(guides => {
        const featured = (guides || []).find(it => it.featuredGuide);
        if (featured) {
          return featured;
        } else {
          return null;
        }
      }),
    );

  public featuredGuide$ = combineLatest([
    this.allPublicGuides$,
    this.featuredGuide
  ]).pipe(
    map(([guides, featured]) => {
      if (featured) {
        return featured;
      } else if (guides?.length > 0) {
        return guides.first();
      } else {
        return null;
      }
    }),
    switchMap(guide => guide?.id ? this.guideApi.GetGuide(guide.id) : of(null)),
    tap(guide => {
      if (!!guide) {
        this.cache.cacheObject<Guide>(Guide.buildCacheKey(guide.id), guide);
      }
    }),
    distinctUntilChanged(DistinctUtils.distinctGuide),
    shareReplay(1)
  );

  // Hydrated Guide Features
  public hydratedGuideFeatures$ = combineLatest([
    this.allGuideFeatures.notNull(),
    this.allGuides.notNull(),
  ]).pipe(
    map(([guideFeatures, guides]) => {
      return guideFeatures.map(gf => {
        const hydratedGuideFeature = new HydratedGuideFeature();
        hydratedGuideFeature.guideFeature = gf;
        hydratedGuideFeature.guides = guides.filter(g => g.tags.intersection(gf.tags).length > 0);
        if (hydratedGuideFeature.guides.length > 0) {
          return hydratedGuideFeature;
        } else {
          return null;
        }
      }).filter(gf => !!gf).sort((a, b) => a.guideFeature.priority - b.guideFeature.priority);
    }),
    distinctUntilChanged(DistinctUtils.distinctHydratedGuideFeatures)
  );

  // Listeners
  // noinspection JSUnusedLocalSymbols
  private listenForInsider = this.insiderId$
    .notNull()
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged())
    .subscribe(insiderId => {
      const cacheKey = Guide.buildArrayCacheKey(insiderId);
      const cachedGuides = this.cache.getCachedArray<Guide>(Guide, cacheKey);
      if (cachedGuides) {
        this.allGuides.next(cachedGuides);
      }
      const getGuides = new GetCompanyGuidesReq(insiderId);
      this.guideApi.GetCompanyGuides(getGuides).subscribe(guides => {
        this.cache.cacheArray<Guide>(Guide.buildArrayCacheKey(insiderId), guides);
        this.allGuides.next(guides);
      }, (error: CustomError) => {
        this.toastService.publishError(error);
      });
    });

  // noinspection JSUnusedLocalSymbols
  private listenForGuideFeatures = this.insiderId$
    .notNull()
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged())
    .subscribe(insiderId => {
      const cacheKey = GuideFeature.buildArrayCacheKey(insiderId);
      const cachedGuideFeatures = this.cache.getCachedArray<GuideFeature>(GuideFeature, cacheKey);
      if (cachedGuideFeatures) {
        this.allGuideFeatures.next(cachedGuideFeatures);
      }
      this.guideApi.GetCompanyGuideFeatures(insiderId).subscribe(guideFeatures => {
        this.cache.cacheArray<GuideFeature>(GuideFeature.buildArrayCacheKey(insiderId), guideFeatures);
        this.allGuideFeatures.next(guideFeatures);
      }, (error: CustomError) => {
        this.toastService.publishError(error);
      });
    });

  constructor(
    private activatedRoute: ActivatedRoute,
    private cache: CacheService,
    private guideApi: GuideApi,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setInsiderId(id: string) {
    this.insiderId.next(id);
  }

  setGuideId(id: string) {
    this.guideId.next(id);
  }

  addGuideView(guide: Guide) {
    this.guideApi.AddGuideView(guide.id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => {
      }, (error: CustomError) => {
        this.toastService.publishError(error);
      });
  }

}
