import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {SortUtils} from '../../modules/krugo-guides/utils/sort-utils';

export class GuideSection implements Deserializable {
  public ids: Map<string, number>; // Map<Id, position>
  public sortOrder: number;
  public title: string;
  // Not from api
  public sortedIds: string[];

  onDeserialize() {
    if (!(this.ids instanceof Map)) {
      this.ids = DeserializeHelper.deserializeGenericMap(this.ids) || new Map();
    }
    // Sort ids
    const tmp = [];
    for (const [key, value] of this.ids.entries()) { tmp.push([key, value]); }
    tmp.sort(SortUtils.sortKeyNumberPairs);
    this.sortedIds = tmp.map(it => it[0]);
  }
}
