import {Deserializable} from '../protocols/deserializable';
import {Font} from '../../modules/krugo-guides/enum/font.enum';

export class InsiderOptions implements Deserializable {
  public primaryFont: Font;
  public secondaryFont: Font;
  public primaryColor: string;
  public secondaryColor: string;

  onDeserialize() {
  }
}
