<div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">

  <div #container class="fill">

    <swiper #swiper
            class="swiper-container"
            [disabled]="(paginatedGuides?.length < 2)"
            [config]="config">

      <app-empty-state *ngIf="paginatedGuides?.length === 0"
                       class="empty-state-container">
        No Guides Found.
      </app-empty-state>
      <app-guides-grid *ngFor="let page of paginatedGuides"
                       [guides]="page.guides">
      </app-guides-grid>

    </swiper>
  </div>

</div>
