import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Insider} from '../../../../../models/shared/insider';
import {BaseModal} from '../../../../../models/base/base-modal';
import {InsiderModalViewModel} from './insider-modal-view-model';
import {GuideClickType} from '../../../../../models/guides/add-guide-view-req';
import {Guide} from '../../../../../models/shared/guide';
import {TypeUtils} from '../../../utils/type-utils';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ScreenService} from '../../../../../services/screen.service';

@Component({
  selector: 'app-insider-modal',
  templateUrl: './insider-modal.component.html',
  styleUrls: ['./insider-modal.component.scss'],
  providers: [InsiderModalViewModel]
})
export class InsiderModalComponent extends BaseModal
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guide: Guide;
  @Input() insider: Insider;
  types = TypeUtils;

  constructor(
    public vm: InsiderModalViewModel,
    private activeModal: NgbActiveModal,
    private screenService: ScreenService,
    private el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
    setTimeout(() => {
      this.screenService.publishScrollPosition.next(this.el.nativeElement?.getBoundingClientRect()?.top ?? 0);
    }, 100);
  }

  setupBindings() {
  }

  cancel() {
    this.activeModal.close(null);
  }

  addView(type: GuideClickType) {
    if (!!this.guide) {
      this.vm.addView(this.guide, type);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
