/* tslint:disable:no-string-literal */
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import 'firebase/analytics';
import {CanDeactivateState} from './services/guards/can-deactivate.guard';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {DrawerScreen, DrawerService} from './services/drawer.service';
import {environment} from '../environments/environment';
import firebase from 'firebase/app';
import {LoggingService} from './services/logging-service';
import {BaseComponent} from './models/base/base-component';
import {combineLatest, timer} from 'rxjs';
import {ScreenService} from './services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public currentScreen: DrawerScreen;
  // noinspection JSUnusedLocalSymbols
  private listenToCurrentScreen = this.drawerService.connectToCurrentScreen()
    .pipe(distinctUntilChanged())
    .subscribe(screen => {
      if (screen) {
        this.currentScreen = screen;
      }
    });

  public latestMessageEvent: MessageEvent<any>;

  constructor(
    private router: Router,
    private drawerService: DrawerService,
    private loggingService: LoggingService,
    private screenService: ScreenService,
  ) {
    super();
    // Config for Firebase
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any; } = {};
    userProperties['Platform'] = 'Embed';
    firebase.analytics().setUserProperties(userProperties);
    this.loggingService.SetFirebaseAppInstance(firebaseApp);

    // Config for Abandon changes on back button
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    ).subscribe();

    window.addEventListener('message', (event) => {
      if (event.data === 'showMeWhatYouGot') {
        this.latestMessageEvent = event;
        this.updateParentHeight();
      }
      return;
    }, false);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.setupViews();
  }

  setupViews() {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    /* This is hear to fix sizing problems with SWIPER.JS
     * There is not another way to automatically resize the swiper.
     * This forces the swiper to check if it need to resize every second.
     */
    timer(0, 1000)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => window.dispatchEvent(new Event('resize')));

    // Bind to scroll position changes
    const scrollSub = combineLatest([
      this.screenService.publishScrollPosition.notNull().pipe(distinctUntilChanged()),
      this.screenService.isMobile.notNull().pipe(distinctUntilChanged()),
    ]).subscribe(([scrollPos, isMobile]) => {
      if ((isMobile && !!this.latestMessageEvent && scrollPos > -1) || (!isMobile && scrollPos === 0)) {
        (this.latestMessageEvent?.source as any)?.postMessage(`ScrollTo:${scrollPos}`, '*');
        this.screenService.publishScrollPosition.next(-1);
      }
    });
    this.pushSub(scrollSub);

    // Bind to scroll height changes
    const heightSub = combineLatest([
      this.screenService.publishIFrameHeight.notNull(),
      this.screenService.isMobile.notNull().pipe(distinctUntilChanged()),
    ]).subscribe((_) => {
      this.updateParentHeight();
    });
    this.pushSub(heightSub);

  }

  ngOnDestroy(): void {
    this.destroy();
  }

  updateParentHeight() {
    if (!!this.latestMessageEvent) {
      if (this.currentScreen) {
        if (this.currentScreen === DrawerScreen.BROWSE_GUIDES) {
          const routing = document.getElementById('routing');
          if (routing) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
            const top = document.getElementById('banner-container')?.getBoundingClientRect()?.top ?? 0;
            const bottom = document.getElementById('powered-by-container')?.getBoundingClientRect()?.bottom ?? 0;
            const bufferToStopScrollBar = 20;
            const height = bottom - top + bufferToStopScrollBar;
            (this.latestMessageEvent.source as any).postMessage(`Height:${height}px`, '*');
          } else {
            (this.latestMessageEvent.source as any).postMessage(`Height:100vh`, '*');
          }
        } else if (this.screenService.isMobile.getValue()) {
          const isMobileMapView = (document.getElementsByClassName('mobile-map-view') ?? []).length > 0;
          if (isMobileMapView) {
            // Map view is present, return 100vh
            (this.latestMessageEvent.source as any).postMessage(`Height:100vh`, '*');
          } else {
            // Looking at singular guide
            const guideHeader = document.getElementById('guide-header-container');
            const guideContainers = document.getElementsByClassName('guide-section-container');
            let guideContainersScrollHeight = 0;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < guideContainers.length; i++) {
              guideContainersScrollHeight += guideContainers[i].scrollHeight;
            }
            if (!!guideHeader && !!guideContainers && guideHeader.scrollHeight > 0 && guideContainersScrollHeight > 0) {
              // Guide List view is present, calculate height (guide-header-container + guide-section-container + 140px)
              const guideListHeight = guideHeader.scrollHeight + guideContainersScrollHeight + 160;
              (this.latestMessageEvent.source as any).postMessage(`Height:${guideListHeight}px`, '*');
            } else {
              (this.latestMessageEvent.source as any).postMessage(`Height:100vh`, '*');
            }
          }
        } else {
          (this.latestMessageEvent.source as any).postMessage(`Height:100vh`, '*');
        }
      } else {
        (this.latestMessageEvent.source as any).postMessage(`Height:100vh`, '*');
      }
    }
  }

}
