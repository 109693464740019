export class OverlayOptions {

  public enabled: boolean = true;
  public color: string = '#222222';
  public opacity: number = 0.3; // between 0 and 1

  static default(): OverlayOptions {
    return new OverlayOptions();
  }

}
