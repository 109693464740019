import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './modules/krugo-guides/components/main/home/home.component';
import {PageNotFoundComponent} from './modules/krugo-guides/components/main/page-not-found/page-not-found.component';


const routes: Routes = [
  {
    path: 'guides/:insiderId',
    component: HomeComponent,
    data: {
      title: 'Krugo Partners Embed'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/krugo-guides/krugo-guides.module').then(m => m.KrugoGuidesModule),
      },
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
