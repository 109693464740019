import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {BaseDirective} from '../../../../../../models/base/base-directive';
import {SelectedMapCard} from '../models/selected-map-card';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {KrugoMapMarker} from '../interfaces/krugo-map-marker';
import {MapCardComponent} from '../components/map-card/map-card.component';
import {DistinctUtils} from '../../../../../../utils/distinct.utils';
import {ScrollUtils} from '../../../../utils/scroll-utils';

@Directive({
  selector: '[appCardSnapScroller]'
})
export class CardSnapScrollerDirective extends BaseDirective
  implements AfterViewInit, OnChanges, OnDestroy {

  @ContentChildren(MapCardComponent) cards: QueryList<MapCardComponent>;
  @Input() select: KrugoMapMarker;
  @Input() cardsAreInFrame: boolean = true;
  @Output() selected = new EventEmitter<SelectedMapCard>(true);
  //
  private scrollToggled = new Subject<boolean>();
  private scrollDebounce = this.scrollToggled
    .pipe(takeUntil(this.onDestroy), debounceTime(250))
    .subscribe(_ => this.onScroll());
  private selectedCard = new BehaviorSubject<[MapCardComponent, number]>([null, 0]);
  private cardSub = this.selectedCard
    .pipe(
      takeUntil(this.onDestroy),
      distinctUntilChanged(DistinctUtils.distinctMapCardComponentWithIndex)
    )
    .subscribe(([card, index]) => {
      if (card) {
        this.selected.emit(new SelectedMapCard(card, index));
      }
    });
  private destroyScrollListener: () => void;

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.select) {
      this.scrollToCard(this.select);
    } else {
      this.onScroll();
    }
    this.destroyScrollListener = this.renderer2.listen(this.el.nativeElement, 'scroll', (_) => {
      this.scrollToggled.next(true);
    });
  }

  onScroll() {
    const cards = this.cards?.toArray();
    const onScreenIndex = cards?.findIndex(c => this.isElementInViewport(c.el.nativeElement));
    if (onScreenIndex > -1) {
      const card = cards[onScreenIndex];
      if (card) { this.selectedCard.next([card, onScreenIndex]); }
    }
  }

  private isElementInViewport(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return rect.right <= (window.innerWidth || document.documentElement.clientWidth) && rect.left >= 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.select) {
      this.scrollToCard(this.select);
    }
  }

  scrollToCard(marker: KrugoMapMarker) {
    const cards = this.cards?.toArray();
    const onScreenIndex = cards?.findIndex(c => c.mapMarker?.getUniqueId() === marker?.getUniqueId());
    if (onScreenIndex > -1) {
      const card = cards[onScreenIndex];
      if (card && this.cardsAreInFrame) {
        ScrollUtils.horizontalScrollIntoView(card.el.nativeElement.parentElement, card.el.nativeElement);
      }
    }
  }

  ngOnDestroy() {
    this.destroy();
    this.destroyScrollListener();
  }

}

