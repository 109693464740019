<div class="modal-header header-container">
  <app-swiper-carousel class="banner"
                       [overlay]="(bannerOverlay | async)"
                       [assets]="item?.getImages()">
  </app-swiper-carousel>
</div>
<div #textContainer class="text-container">
  <div appFont
       class="item-title"
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Bold">{{item?.getTitle()}}</div>
  <div appFont
       class="item-desc"
       [hierarchy]="types.FontHierarchy.Secondary"
       [type]="types.FontType.Regular">
    {{item?.getDesc()}}
  </div>

  <!-- Social link section -->
  <br *ngIf="item?.hasSocials() || item?.getUrl()">

  <div style="padding: 0 16px">
    <app-social-links
      [facebookUrl]="item?.getFacebookUrl()"
      [instagramUrl]="item?.getInstagramUrl()"
      [twitterUrl]="item?.getTwitterUrl()"
      (socialClick)="addView($event)">
    </app-social-links>
  </div>

  <a *ngIf="item?.getUrl()"
     class="url"
     target="_blank" rel="noopener noreferrer"
     (click)="addView(types.GuideClickType.WEBSITE)"
     [href]="item?.getUrl()">
    View Website
  </a>
</div>
<div class="modal-footer footer-container" fxLayout="row" fxLayoutAlign="flex-end center">
  <button matRipple
          (click)="close()"
          [style.background-color]="'black'"
          class="kp-button">
    <mat-icon class="close-icon" [style.color]="'white'">close</mat-icon>
  </button>
  <button matRipple
          *ngIf="vm.isMobile$|async"
          [style.background-color]="vm.primaryColor$|async"
          (click)="viewOnMap()"
          class="kp-button view-on-map">
    <mat-icon class="marker-icon" [style.color]="'white'">location_on</mat-icon>
  </button>
</div>
