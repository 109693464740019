import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowseGuidesComponent} from './components/main/browse-guides/browse-guides.component';
import {BrowseGuideComponent} from './components/main/browse-guide/browse-guide.component';

const routes: Routes = [
  {
    path: '',
    component: BrowseGuidesComponent,
    data: {
      title: 'Guides',
      animation: 'Guides'
    }
  },
  {
    path: 'guide/:guideId',
    component: BrowseGuideComponent,
    data: {
      title: 'Guide',
      animation: 'Guide'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KrugoGuidesRoutingModule { }

