import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {DrawerService} from '../../../../../services/drawer.service';
import {BrowseGuideViewModel} from './browse-guide-view-model';
import {ScreenService} from '../../../../../services/screen.service';

@Component({
  selector: 'app-browse-guide',
  templateUrl: './browse-guide.component.html',
  styleUrls: ['./browse-guide.component.scss'],
  providers: [BrowseGuideViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseGuideComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public guide = this.vm.selectedGuide;
  public selectedMapMarker$ = this.vm.selectedMapMarker$;
  public loadingOpts = this.vm.loadingOpts;

  constructor(
    public vm: BrowseGuideViewModel,
    private drawerService: DrawerService,
    private screenService: ScreenService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    this.drawerService.enteredGuideView();
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy() {
    this.vm.destroy();
    this.destroy();
  }

}
