<div class="created-by-container" fxLayout="column" fxLayoutAlign="start start">
  <app-insider *ngIf="guide?.hasInsider() && guide?.showCompanyInsider"
               (click)="openInsider(guide?.insider)"
               [insider]="guide?.insider">
  </app-insider>
  <app-insider *ngIf="guide?.hasCompany() && (!guide?.hasInsider() || !guide?.showCompanyInsider)"
               (click)="openInsider(guide?.company)"
               [insider]="guide?.company">
  </app-insider>
  <div *ngIf="guide?.hasCompany() && guide?.hasInsider() && guide?.showCompanyInsider"
       class="concat"
       style="margin-top: 0.5rem;"
       appFont
       [hierarchy]="types.FontHierarchy.Primary"
       [type]="types.FontType.Regular">&nbsp;In collaboration with&nbsp;<div matRipple (click)="openInsider(guide?.company)" class="contributor">{{guide?.company?.companyName}}</div></div>

</div>
