import {Component, Input, OnInit} from '@angular/core';
import {Insider} from '../../../../../models/shared/insider';
import {TypeUtils} from '../../../utils/type-utils';

@Component({
  selector: 'app-insider',
  templateUrl: './insider.component.html',
  styleUrls: ['./insider.component.scss']
})
export class InsiderComponent implements OnInit {

  @Input() insider: Insider;
  types = TypeUtils;

  constructor() {}

  ngOnInit(): void {
  }

}
