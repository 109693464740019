<form class="search-bar-container"
      (reset)="emitEmpty()">
    <label for="searchBar" hidden>Search Label</label>
    <input appFont
           [hierarchy]="types.FontHierarchy.Primary"
           [type]="types.FontType.Regular"
           (input)="searchChanged($event)"
           class="text-field search-text-field" id="searchBar" name="searchBar" placeholder="{{placeHolder}}"
           type="text">
    <button appFont
            [hierarchy]="types.FontHierarchy.Primary"
            [type]="types.FontType.Regular"
            class="clear-search"
            type="reset">
      CLEAR
    </button>
</form>
