import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Guide} from '../../../../../models/shared/guide';
import {TypeUtils} from '../../../utils/type-utils';
import {FeaturedGuideBannerViewModel} from './featured-guide-banner-view-model';
import {NavigatorService} from '../../../../../services/navigator.service';

@Component({
  selector: 'app-featured-guide-banner',
  templateUrl: './featured-guide-banner.component.html',
  styleUrls: ['./featured-guide-banner.component.scss'],
  providers: [FeaturedGuideBannerViewModel]
})
export class FeaturedGuideBannerComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: Guide;
  types = TypeUtils;

  constructor(
    public vm: FeaturedGuideBannerViewModel,
    private navigator: NavigatorService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public goToGuide() {
    this.navigator.toGuide(this?.guide);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
