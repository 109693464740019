import {Injectable, SecurityContext} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {InsiderDomainModel} from '../../../domain/insider-domain-model';
import {ScreenService} from '../../../../../services/screen.service';
import {GuidesDomainModel} from '../../../domain/guides-domain-model';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../../models/enum/shared/loading-spinner-size.enum';
import {debounceTime, delay, map, switchMap, tap} from 'rxjs/operators';
import {AssetSize} from '../../../../../models/enum/dto/asset-size.enum';
import {DomSanitizer} from '@angular/platform-browser';
import {KrugoMapMarker} from '../map/interfaces/krugo-map-marker';
import {Asset} from '../../../../../models/image/dto/asset';

export type SocialExportType = 'story' | 'square' | 'facebook' | 'twitter';

@Injectable()
export class ShareToSocialModalViewModel extends BaseViewModel {

  primaryColor$ = this.insiderDomainModel.primaryColor$;
  isMobile$ = this.screenService.connectToIsMobile();
  closeModal = new Subject<boolean>();
  loadingOpts = this.getLoadingOptions(false);
  exportOptions: SocialExportType[] = ['story', 'square', 'facebook', 'twitter'];
  selectedOption: SocialExportType = 'story';
  postImage$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.images.find(i => i.isImage())?.getAssetUrl(AssetSize.Large)),
    switchMap(src => src?.srcUrl ?? of('assets/placeholder/no-image.svg')),
    map(url => this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url))
  );

  postInsider$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => {
      if (!g.hasInsider() || !g.showCompanyInsider) {
        return g.company;
      }
      return g.insider;
    })
  );

  insiderImage$ = this.postInsider$.firstNotNull().pipe(
    map(i =>  i.profilePicture?.getAssetUrl(AssetSize.Medium)),
    switchMap(src => src?.srcUrl ?? of('assets/placeholder/no-image.svg')),
  );

  postInsiderName$ = this.postInsider$.firstNotNull().pipe(
    map(i => i.getFullName()),
  );

  postInsiderCollaborationText$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => {
      if (!g.hasInsider() || !g.showCompanyInsider) {
        return null;
      }
      return `In collaboration with ${g.company.companyName}`;
    }),
  );

  postTitle$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.title),
  );

  postDescription$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.description),
  );

  postSpots$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.sections?.flatMap(s => s.sortedIds)?.length ?? 0),
    map(spots => spots === 1 ? `${spots} Spot` : `${spots} Spots`),
  );

  postWebsite$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.company ?? g.insider),
    map(insider => {
      return !!insider && !!insider?.websiteUrl ? insider.websiteUrl : 'krugopartners.com';
    }),
    map(url => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')), // remove prefix from url
  );

  postPlaces$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => {
      return g.sections?.flatMap(s => s.sortedIds).slice(0, 3).map(id => g.findPlanItem(id));
    }),
  );

  postMorePlaces$ = this.guidesDomainModel.selectedGuide$.firstNotNull().pipe(
    map(g => g.sections?.flatMap(s => s.sortedIds)?.length ?? 0),
    map(places => places > 3 ? places - 3 : 0),
    map(additionalPlaces => additionalPlaces > 0 ? `and ${additionalPlaces} more...` : ``),
  );

  postPlaceAssetsLoaded$ = this.postPlaces$.pipe(switchMap(places => {
    return combineLatest(places?.map(p => {
      const asset = this.getPlanItemImageAsset(p);
      let o: Observable<any>;
      if (asset?.isImage()) {
        o = asset.getAssetUrl(AssetSize.Medium)?.srcUrl;
      } else if (asset?.isVideo()) {
        o = asset.getAssetUrl(AssetSize.Large)?.srcUrl.pipe(delay(10000));
      }
      return o ?? of(true);
    })).pipe(map(content => content.every(c => !!c)));
  }));

  contentLoadedSubject$ = new BehaviorSubject(false);
  private isContentLoaded = combineLatest([this.postImage$, this.insiderImage$, this.postPlaceAssetsLoaded$])
    .pipe(map(content => content.every(c => !!c)))
    .subscribe(this.contentLoadedSubject$);

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insiderDomainModel: InsiderDomainModel,
    private screenService: ScreenService,
    private sanitizer: DomSanitizer,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.subscriptions.push(this.isContentLoaded);
  }

  getPlanItemImageAsset(planItem: KrugoMapMarker): Asset {
    return planItem.getImages()
      // get the first image, otherwise use video if its the only option
      ?.sort((a, b) => (a.isImage() === b.isImage()) ? 0 : a.isImage() ? -1 : 1)
      ?.find(i => !!i);
  }

  getTitleForExportType(exportType: SocialExportType): string {
    switch (exportType) {
      case 'story':
        return `Instagram Story`;
      case 'square':
        return `Instagram Post`;
      case 'facebook':
        return `Facebook Post`;
      case 'twitter':
        return `Twitter`;
    }
  }

  getSizeForExportType(exportType: SocialExportType): string {
    return `${this.getWidthForExportType(exportType)} × ${this.getHeightForExportType(exportType)}`;
  }

  getHeightForExportType(exportType: SocialExportType): number {
    switch (exportType) {
      case 'story':
        return 1920;
      case 'square':
        return 1080;
      case 'facebook':
        return 630;
      case 'twitter':
        return 506;
    }
  }

  getWidthForExportType(exportType: SocialExportType): number {
    switch (exportType) {
      case 'story':
      case 'square':
        return 1080;
      case 'facebook':
        return 1200;
      case 'twitter':
        return 1012;
    }
  }

  private getLoadingOptions(loading: boolean): LoadingOptions {
    const x = LoadingOptions.default(false, false);
    x.isLoading = loading;
    x.spinnerColor = '#000000';
    x.spinnerSize = LoadingSpinnerSize.Medium;
    x.color = '#000000';
    return x;
  }
}
